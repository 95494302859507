@mixin submenu{
    position: absolute;
    top: 100%;
    list-style-type: none;

    text-align: left;
    
    list-style-type: none;
    // background-color: var(--cl_b, #000);
    // background-color: #7C7C7C;
    &>li{
        // background-color: #7C7C7C;
        background-color: #9E9E9EE6;
    }
    &>li>a{
        display: block;
        @include txtSubMenu;

        padding: 6px 10px;

        transition: .16s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover, &:focus{
            transform: translateX(4px);
        }
    }

    &>li:not(:first-child){
        // border-top: 1px solid var(--cl_underl);
        border-top: 1px solid #E0E0E0;

    }

    &>li.active{
        background-color: var(--cl_b, #000);
        &>a{
            // opacity: 0.6;
        }
        // pointer-events: none;
    }
}

.submenu{
    position: absolute;
    top: 100%;
    list-style-type: none;

    text-align: left;
    list-style-type: none;
    // background-color: var(--cl_b, #000);
    &>li{
        // background-color: #7C7C7C;
        background-color: #9E9E9EE6;
    }
    &>li>a{
        display: block;
        @extend .txtSubMenu;

        padding: 6px 10px;

        transition: .16s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover, &:focus{
            transform: translateX(4px);
        }
    }

    &>li:not(:first-child){
        // border-top: 1px solid var(--cl_underl);
        border-top: 1px solid #E0E0E0;
    }

    &>li.active{
        // opacity: 0.4;
        background-color: var(--cl_b, #000);

        // pointer-events: none;
    }
}

@mixin txtMenu{
    color: var(--clText, #FFF);
    text-transform: uppercase;
    font-size: 14px;
    @include semi-bold;
    
    line-height: 136%;
}

.txtMenu{
    color: var(--clText, #FFF);
    text-transform: uppercase;
    font-size: 14px;
    @include semi-bold;

    line-height: 136%;
}

@mixin txtSubMenu{
    @include medium;
    color: var(--cl_w);
    // font-size: 13px;
    @include content(13px);
    line-height: 136%;
}

.txtSubMenu{
    @include medium;
    color: var(--cl_w);
    // font-size: 13px;
    @include content(13px);
    line-height: 136%;
}

.header{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;

    --clText: #{var(--cl_b)};
    background-color: var(--cl-w, #FFF);

    border-bottom: 1px solid var(--cl_underl);
    
    &.header-fixed{
        position: fixed;
        --clText: #{var(--cl_w)};
        border-bottom: 0px;
        background-color: #ffffff00;

        &::after{
            z-index: -1;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; 
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.52) 0%, rgba(69, 69, 69, 0) 100%);
            // background: linear-gradient(281.44deg, rgba(0, 0, 0, 0.49) 18.6%, rgba(69, 69, 69, 0) 36.63%);
            // transform: matrix(1, 0, 0, -1, 0, 0);
            
            // background: linear-gradient(110.24deg, rgba(255, 255, 255, 0.65) -4.24%, rgba(217, 217, 217, 0) 27.88%);
            // background: linear-gradient(110.24deg, rgba(255, 255, 255, 0.65) -4.24%, rgba(217, 217, 217, 0) 27.88%);   
        }
    }

    &.scrolling{
        position: fixed;
        top: 0;
        left: 0;

        transition: .3s all linear;
        background-color: #FFF;
        
        & ~ main{
            .works-section--sticky, .news-section-nav{
                top: 68px;
                @include rpmin(lg){
                    top: 91px;
                }
            }
            
        }
    }

    &-logo{
        display: block;
        padding: 16px 0;

        img, svg{
            width: auto;
            height: 36px;
            @include rpmin(lg){
                height: 56px;
            }
        }
    }

    &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-menu{
        display: flex;
        align-items: stretch;
        @include rpmin(md){
            position: relative;
        }

        nav{
            @include rpmax(md){
                clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
                visibility: hidden;
                opacity: 0;
                transition: .16s all linear;
                user-select: none;

                &.showed-mb{
                    user-select: initial;
                    opacity: 1;
                    visibility: visible;

                    transform: translate(0, 0);
                    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
                }

                --clText: #FFF;

                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: calc(100vh - #{var(--hHeader, 0px)});
                background-color: var(--cl_b, #000);

                ul{
                    list-style-type: none;
                }

                &>ul{
                    &>li{
                        display: flex;
                        justify-content: space-between;
                        align-items: stretch;
                        flex-wrap: wrap;
                        
                        .icon{
                            flex: 1;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding: 0 2vw;

                            transition: .16s all ease-in-out;

                            &>*{
                                width: 16px;
                            }
                        }

                        &.is-actived{
                            .icon{
                                transform: rotate(180deg);
                            }
                        }
                    }
                    &>li>a{
                        display: block;
                        @include txtMenu;

                        padding: 18px 2vw;
                    }
                    &>li{
                        ul{
                            flex: 0 0 100%;
                            background-color: var(--cl_gray);
                            border-top: 1px solid var(--cl_underl);

                            padding: 10px 0;
                            
                            &>li{
                                padding: 10px calc(2vw + 6px);
                                &>a{
                                    display: inline-block;
                                    @include txtSubMenu;
                                }
                            }
                        }
                    }

                    &>li:not(:first-child){
                        border-top: 1px solid var(--cl_underl);
                    }
                
                    // &>li.active>a{
                    //     opacity: 0.4;                        
                    // }
                }
                ul>li.active>a{
                    opacity: 0.6;
                }


            }
            @include rpmin(md){
                &>ul{
                    list-style-type: none;
        
                    display: flex;
                    justify-content: flex-end;
                    
                    &>li{
                        margin-left: 16px;
                        padding: 24px 0;
                        
                        @include rpmin(lg){
                            padding: 36px 0;
                        }

                        .icon{
                            display: none;
                        }
    
                        &>ul{
                            width: 100vw;
                            @include submenu;

                            display: none;
                        }
        
                        &:hover, &>li:focus{
                            &>ul{
                                display: block;
                            }
                        
                            &>a{
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
    
                        &>a{
                            &:focus{
                                & ~ ul{
                                    display: block;
                                }
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
                        &.active{        
                            &>a{
                                color: var(--cl_main);
                                &::after{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    
                    &>li>a{
                        display: block;
                        @include txtMenu;
    
                        position: relative;
                        @include hoverUnder;
                    }
                }
            }
        }

        .languages{
            margin-left: 16px;
            display: flex;
            align-items: center;
            transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);

            position: relative;
            padding: 4px 0;

            &__thumbnail{
                display: block;
                width: 24px;
                height: 16px;
                &>*{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 3px;
                }
            }

            // &__thumbnail{
            //     cursor: pointer;

            //     display: flex;
            //     align-items: center;

            //     @extend .txtMenu;

            //     [fill]:not([fill="none"]){
            //         fill: var(--clText, #000);
            //     }

            //     [stroke]:not([stroke="none"]){
            //         stroke: var(--clText, #000);
            //     }

            //     .icon{
            //         margin-left: 4px;
            //     }
            // }

            // &-ls{
            //     @extend .submenu;

            //     height: auto;
            //     display: none;
                
            //     position: absolute;
            //     right: 0;
            //     top: 100%;

            //     &>li>a{
            //         &:hover, &:focus{
            //             transform: translateX(2px);
            //         }
            //     }
            // }

            // &:hover .languages-ls{
            //     display: block;
            // }
        }

        .header-search{
            margin-left: 16px;
            display: flex;
            align-items: center;
            .header-search-icon{
                color: var(--clText, #000);
    
                [stroke]:not([stroke="none"]){
                    stroke: currentColor;
                }
                [fill]:not([fill="none"]){
                    fill: currentColor;
                }
                &:hover{
                    color: var(--cl_main, #000);
                }
            }

            &-wrapper{
                z-index: 111;
                position: absolute;
                top: var(--hHeader, 0px);
                right: 0;
                width: 100%;
                min-width: 180px;
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                transition: .3s all ease-in-out;
                background-color: #9E9E9EE6;
                
                &>.search-container{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    position: relative;
                    padding: 20px 0px;
                    @include rpmin(md){
                        padding: 40px 0px;
                    }
    
                    .close{
                        position: absolute;
                        top: 14px;
                        right: 0;
                        display: flex;
                        align-items: center;
                        color: #FFF;
    
                        font-size: 14px;
                        font-weight: 400;
    
                        [stroke]:not([stroke="none"]){
                            stroke: currentColor;
                        }
                        [fill]:not([fill="none"]){
                            fill: currentColor;
                        }

                        .icon{
                            margin-right: 4px;
                        }
    
                        transition: .16s all linear;
    
                        &:hover{
                            transform: scale(1.1);
                        }

                        @include rpmax(md){
                            &>span{
                                display: none;
                            }
                        }
                    }
                }
    
                form{
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 96vw;



                    &>*{
                        padding: 0;
                    }
                    .group-input{    
                        width: 100%;
                        display: flex;
                        flex-direction: row;
    
                        ::-webkit-input-placeholder { /* Edge */
                            // color: #efefefc9;
                            color: #1a1a1a;
                        }
                        
                        :-ms-input-placeholder { /* Internet Explorer 10-11 */
                            // color: #efefefc9;
                            color: #1a1a1a;
                        }
                        
                        ::placeholder {
                            // color: #efefefc9;
                            color: #1a1a1a;
                        }
    
                        padding-bottom: 6px;
                        border-bottom: 1px solid #000;
    
                        input{
                            appearance: none;
                            background: none;
                            outline: none;
                            border: none;
    
                            width: auto;
                            padding: 0;
                            
                            flex: 1;
                            
                            // color: #EFEFEF;
                            color: #000;
                            font-size: 14px;

                            // border-bottom: 1px solid #EEEEEE;
                        }
    
                        button[type="submit"], input[type="submit"]{
                            appearance: none;
                            background: none;
                            outline: none;
                            border: none;
    
                            display: flex;
                            justify-content: center;
                            align-items: center;
    
                            font-size: 14px;
                            text-transform: uppercase;
                            background-color: #000;
                            color: #FFF;
                            // padding: 8px 20px;
                            transition: .16s all linear;

                            

                            @include rpmin(md){
                                display: none;
                            }
                            &:hover{
                                background-color: var(--cl_main, #000);
                            }
                        }
                    }

                    @include rpmin(md){
                        max-width: 520px;

                        .group-input{
                            padding: 0 20px;
                            border-bottom: none;
                            
                            input{
                                padding-bottom: 6px;
                                border-bottom: 1px solid #000;
                            }
                        }
                    }
                }
            }

            &:hover{
                .header-search-icon{
                    color: var(--cl_main, #000);
                }

                .header-search-wrapper{
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                }
            }


        }
    }

    &-toggle-mb{
        display: none;
        @include rpmax(md){
            display: flex;
            justify-content: center;
            align-items: center;

            width: 26px;
            height: 30px;
            margin-left: 14px;
            position: relative;


            &::before, &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 54%;
                height: 0;
                border-bottom: 2px solid var(--clText, #000);
                transform: translate(-50%, -50%);

                transition: .16s all ease-in-out;
            }
            
            &::before{
                box-shadow: 0 5px 0 var(--clText, #000), 
                            0 -5px 0 var(--clText, #000);
            }
            &.active{
                &::before{
                    box-shadow: none;
                    transform: translate(-50%, -50%) rotate(-45deg);    
                }
                &::after{
                    transform: translate(-50%, -50%) rotate(45deg);    
                }
            }

        }
    }
}


// .header{
//     transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
//     background-color: transparent;
    
//     position: fixed;
//     top: 0%;
//     width: 100%;
//     z-index: 999;
//     display: block;
//     color: #000;

//     // .logo{
//     //     opacity: 0;
//     //     transform: scale(0);
//     // }


//     &::after{
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background-color: #FFF;
//         z-index: -1;
//     }

//     &.scroll{
//         transition: .16s all linear;

//         &::after{
//             height: 100%;
//             box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.15);
//         }
//     }

//     // *{
//     //     transition: .16s all ease-in-out;
//     // }

//     &-container{
//         display: flex;
//         align-items: center;
//     }

//     &-navbar{
//         @include rpmax(lg){
//             position: absolute;
//             top: 100%;
//             left: calc(-100% - 2vw);
//             width: 100vw;
//             height: calc(100vh - 70px);
//             background-color: #0D004C;

//             transition: .3s left cubic-bezier(0.215, 0.610, 0.355, 1);

//             &>ul{
//                 padding: 10px 0;
//                 list-style-type: none;

//                 &>li{  
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                     flex-wrap: wrap;

//                     a{
//                         color: #FFF;
//                         font-size: 14px;
//                         padding: 24px 2vw;
//                     }

//                     span.icon{
//                         display: flex;
//                         justify-content: flex-end;
//                         flex: 1;
//                         padding: 24px 2vw;

//                         svg{
//                             width: 10px;
//                             transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                         }
//                     }

//                     &.active{
//                         &>a{
//                             color: #8BC53F;
//                         }
//                     }

//                     &.showed{
//                         &>ul{
//                             padding: 20px 20px;
//                             height: auto;
//                             opacity: 1;
//                             visibility: visible;
//                         }

//                         .icon{
//                             svg{
//                                 transform: rotate(180deg);
//                             }
//                         }
//                     }

//                     ul{
//                         height: 0;
//                         opacity: 0;
//                         visibility: hidden;

//                         flex: 0 0 100%;
//                         list-style-type: none;
//                         background-color: #FFFF;

//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                         &>li>a{
//                             display: block;
//                             padding: 10px 2vw;
//                             @include content(18px);
//                             color: #333333;;

//                             &:hover{
//                                 color: #8BC53F;
//                             }
//                         }
//                         li.active{
//                             a{
//                                 color: #8BC53F !important;
//                             }
//                         }
//                     }

//                     & + li{
//                         border-top: 1px solid #F3F3F3;
//                     }
//                 }
//             }

//             &.toggle{
//                 left: 0;
//             }
//         }

//         @include rpmin(lg){
//             flex: 1;
//             display: flex;
//             align-items: center;
//             justify-content: flex-end;
    
//             padding-top: 45px;
            
//             ul{
//                 list-style-type: none;    
//             }
            
//             &>ul{
//                 display: flex;
//                 font-family: Arial, sans-serif;
                
//                 &>li{
//                     // padding-right: 37px;
//                     // padding-bottom: 45px;
    
//                     padding: 0 37px 45px 0;
//                     position: relative;
//                     transition: .2s all cubic-bezier(0.215, 0.610, 0.355, 1);
//                     .icon{
//                         display: none;
//                     }

//                     &.active{
//                         &>a{
//                             color: #8BC53F;
//                         }
//                     }

//                     &>a{
//                         // font-size: 16px;
//                         @include content(16px);
//                         color: #272C33;
//                         @include regular;
//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                         white-space: nowrap;

//                         &:focus{
//                             color: #8BC53F;
    
//                             & ~ .submenu{
//                                 opacity: 1;
//                                 visibility: visible;
//                                 transform: translateY(0px);
//                             }
//                         }


//                     }
    
//                     &:hover{
//                         &>a{
//                             color: #8BC53F;
//                         }
    
//                         .submenu{
//                             opacity: 1;
//                             visibility: visible;
//                             transform: translateY(0px);
//                         }
//                     }
//                 }
//             }
    
//             .submenu{
//                 // display: none;
    
//                 opacity: 0;
//                 visibility: hidden;
//                 transform: translateY(40px);
//                 transition: .2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    
//                 position: absolute;
//                 top: 100%;
//                 left: 0;
//                 height: auto;
    
//                 background-color: #FFF;
//                 box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.15);
    
//                 padding: 30px 20px;
//                 min-width: 216px;
                
//                 &>li{
//                     display: block;
//                     white-space: nowrap;
    
//                     &>a{
//                         display: block;
//                         @include regular;
//                         // font-size: 18px;
//                         @include content(18px);
//                         color: #333333;
    
//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                         position: relative;
//                         z-index: 1;
    
//                         &:focus{
//                             color: #8BC53F;
//                         }
//                     }
    
//                     & + li{
//                         margin-top: 20px;
//                     }
    
//                     position: relative;
    
//                     &::after{
//                         content: "";
//                         position: absolute;
//                         top: 4px;
//                         left: -20px;
//                         z-index: 0;
//                         width: 0;
//                         height: 0;
//                         border-top: 13px solid #8BC53F;
//                         border-bottom: 0px solid transparent;
//                         border-right: 13px solid transparent;
//                         opacity: 0;
//                         transform: translate(20px, 100%);
//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                     }
                    
//                     &:hover, &.active{
//                         &::after{
//                             opacity: 1;
//                             transform: translate(0%, 0px);
//                         }
//                         &>a{
//                             color: #8BC53F;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .logo{
//         flex: 1;
//         @include rpmin(lg){
//             flex: 0 0 auto;
//         }
//         height: 33px;
//         width: auto;
//         &>*{
//             height: 100%;
//             width: auto;
//             object-fit: contain;
//         }
//     }

//     .languages{
//         *{
//             transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//         }
//         .opt{
//             position: relative;
//             padding: 26px 0;
            
//             font-size: 16px;
//             @include rpmin(lg){
//                 padding: 45px 0;
//             }


//             &__link{
//                 display: flex;
//                 align-items: center;

//                 &:focus{
//                     color: #8BC53F;

//                     .opt__icon{
//                         transform: rotate(-180deg);
//                         path{
//                             &[fill]:not([fill="none"]){
//                                 fill: #8BC53F;
//                             }
//                         }
//                     }

//                     & ~ .opt__select{
//                         opacity: 1;
//                         visibility: visible;
//                         transform: translateY(0px);
//                     }
//                 }

//                 .text{
//                     margin-left: 6px;
//                 }
//             }

//             &:hover{
//                 .opt__link{
//                     color: #8BC53F;

//                     .opt__icon{
//                         transform: rotate(-180deg);
//                         path{
//                             &[fill]:not([fill="none"]){
//                                 fill: #8BC53F;
//                             }
//                         }
//                     }
//                 }
//                 .opt__select{
//                     opacity: 1;
//                     visibility: visible;
//                     transform: translateY(0px);
//                 }
//             }

//             &__select{
//                 opacity: 0;
//                 visibility: hidden;
//                 transform: translateY(30px);
//                 transition: .2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    
//                 position: absolute;
//                 top: 100%;
//                 right: -16px;
//                 height: auto;
    
//                 background-color: #FFF;
//                 box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.15);
    
//                 padding: 10px 0;
//                 text-align: left;

//                 list-style-type: none;

//                 .item{
//                     padding: 0 16px;
//                     position: relative;
                    
//                     &::after{
//                         content: "";
//                         position: absolute;
//                         top: 4px;
//                         left: 0px;
//                         z-index: 0;
//                         width: 0;
//                         height: 0;
//                         border-top: 8px solid #8BC53F;
//                         border-bottom: 0px solid transparent;
//                         border-right: 8px solid transparent;
//                         opacity: 0;
//                         transform: translate(20px, 100%);
//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                     }

//                     a{
//                         display: block;
//                         @include regular;
//                         font-size: 18px;
//                         color: #333333;

//                         transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
//                         position: relative;
//                         z-index: 1;

//                         &:focus{
//                             color: #8BC53F;
//                         }
//                     }
                
//                     &:not(:first-child){
//                         margin-top: 6px;
//                     }

//                     &:hover{
//                         &::after{
//                             opacity: 1;
//                             transform: translate(0%, 0px);
//                         }
//                         &>a{
//                             color: #8BC53F;
//                         }
//                     }
//                 }
//             }
//         }
        
//     }

//     .toggle-mb{
//         display: none;

//         @include rpmax(lg){
//             display: block;
//             width: 26px;
//             height: 30px;
//             background-color: transparent;
    
//             margin-left: 14px;
//             position: relative;
//             cursor: pointer;
            
//             .line{
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translate(-50%, -50%);
//                 width: 18px;
//                 height: 0;
//                 border-bottom: 2px solid #000;
    
//                 transition: .3s all cubic-bezier(0.39, 0.575, 0.565, 1);
                
//                 &:nth-of-type(1){
//                     top: calc(50% - 5px);
//                 }
//                 &:nth-of-type(3){
//                     top: calc(50% + 5px);
//                 }
//             }
    
//             &.active{
//                 .line{
//                     &:first-child{
//                         opacity: 0;
//                     }
//                     &:nth-of-type(2){
//                         top: 50%;
//                         transform: translate(-50%, -50%) rotate(45deg);
//                     }
//                     &:nth-of-type(3){
//                         top: 50%;
//                         transform: translate(-50%, -50%) rotate(-45deg);
//                     }
//                 }
//             }
//         }
//     }
    
//     &.scroll{
//         box-shadow: 0 1px 1px rgba(0,0,0,.1);

//         &::after{
//             height: 100%;
//             visibility: visible;
//             clip-path: circle(112% at 50% 90%);
//         }

//         .header-left, .header-right, .header-center{
//             padding-top: 10px;
//             @include rpmin(lg){
//                 padding-top: 0px;
//             }
//         }

//         .header-center{
//             svg{
//                 display: none;
//             }
//         }
//         .header-logo{
//             &::after{
//                 transform: translate(-50%, -50%);
//                 visibility: visible;

//                 // box-shadow: 0 2px 2px 2px rgba(0,0,0,.1);
//             }
//         }

//         .header-nav{
//             li a{
//                 color: #15A9A6;
//                 background: transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;
//             }

//             li.active{
//                 a{
//                     color: #ECDCBE;
//                 }
//             }

//             li a:hover, li a:focus{
//                 color: #ECDCBE;
//             }
//         }

//         .header__languages{
//             .languages-opt{
//                 color: #15A9A6;
//                 background: transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;

//                 &:hover, &:focus, &:focus{
//                     color: #ECDCBE;
//                 }
//             }
//         }

//         .header__accounts{
//             path{
//                 fill: #15A9A6;
//             }
//             circle{
//                 stroke: #15A9A6;
//             }

//             &:hover, &:focus, &:focus, &.active{
//                 // background-color: #15A9A6;

//                 svg{
//                     fill: #15A9A6;
//                 }

//                 path{
//                     fill: #fff;
//                 }
//                 circle{
//                     stroke: #fff;
//                 }
//             }

//             .navsub{
//                 background-color: #CACBCE;
//                 ul{
//                     &::after{
//                         border-bottom: 5px solid #CACBCE;
//                     }
//                 }
//             }
//         }

//         .header-mb{
//             &__primary{
//                 .line{
//                     background: #15A9A6;
//                 }
//             }

//             &-menu{
//                 clip-path: circle(0px at calc(2vw + 12px) 45px);
//                 &.show{
//                     display: block;
//                     clip-path: circle(135% at calc(2vw + 12px) 45px);
//                 }

//                 .content{
//                     padding-top: 10px;
//                 }
//             }
//         }
//     }
// }