@mixin rpSize($lg, $md, $default, $important: ""){
    @if ($important == ""){
        font-size: $default;
    
        @include rpmin(md){
            font-size: $md;
        }
    
        @include rpmin(lg){
            font-size: $lg;
        }
    }
    @else{
        font-size: $default $important;
    
        @include rpmin(md){
            font-size: $md $important;
        }
    
        @include rpmin(lg){
            font-size: $lg $important;
        }
    }
}
@mixin rpSpace($space, $lg, $md, $default){
    #{$space}: $default;

    @include rpmin(md){
        #{$space}: $md;
    }

    @include rpmin(lg){
        #{$space}: $lg;
    }
}
@mixin rpSpaceSection($space, $lg, $md, $default){
    #{$space}: $default 0;

    @include rpmin(md){
        #{$space}: $md 0;
    }

    @include rpmin(lg){
        #{$space}: $lg 0;
    }
}

@mixin spGap($val, $topCurrent:0){
    // gap: $val;
    margin-top: -$val + $topCurrent;
    margin-left: -$val;

    &>*{
        padding-top: $val;
        padding-left: $val;
    }
}
@mixin spGap2($val1, $val2, $topCurrent:0){
    // gap: $val1 $val2;
    margin-top: -$val1 + $topCurrent;
    margin-left: -$val2;

    &>*{
        padding-top: $val1;
        padding-left: $val2;
    }
}


@mixin title($val, $important: ""){
    //home
    @if ($val == 52px){
        @include rpSize(48px, 34px, 32px, $important);
    }
    @else if ($val == 38px){
        @include rpSize($val, 34px, 32px, $important);
    }
    @else if ($val == 28px){
        @include rpSize($val, 24px, 22px, $important);
    }
    @else if ($val == 22px){
        @include rpSize($val, 20px, 20px, $important);
    }
    @else if ($val == 20px){
        @include rpSize($val, 20px, 20px, $important);
    }
}
@mixin content($val, $important: ""){
    @if ($val == 20px){
        @include rpSize($val, 20px, 20px, $important);
    }
    @else if ($val == 18px){
        @include rpSize($val, 18px, 18px, $important);
    }
    @else if ($val == 16px){
        @include rpSize($val, 16px, 16px, $important);
    }
    @else if ($val == 14px){
        @include rpSize($val, 14px, 14px, $important);
    }
    @else if ($val == 13px){
        @include rpSize($val, 13px, 13px, $important);
    }
    @else if ($val == 12px){
        @include rpSize($val, 12px, 12px, $important);
    }
}


@mixin space($key, $val){
    @if ($val == 100px){
        @include rpSpace($key, $val, 80px, 60px);
    }
    @else if ($val == 60px){
        @include rpSpace($key, $val, 40px, 40px);
    }
    @else if ($val == 40px){
        @include rpSpace($key, $val, 34px, 30px);
    }
}

@mixin space-section($key, $val){
    @if ($val == 100px){
        @include rpSpaceSection($key, $val, 80px, 60px);
    }
    @else if ($val == 60px){
        @include rpSpaceSection($key, $val, 40px, 40px);
    }
    @else if ($val == 40px){
        @include rpSpaceSection($key, $val, 34px, 30px);
    }
}