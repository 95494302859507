.news-section{
    &>.container{
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        margin-top: 40px;
        // @include spGap(40px, 40px);
        // @include rpmin(md){
        //     // margin-top: 50px;
        //     @include spGap(40px, 50px);
        // }
    }

    &-nav{
        position: sticky;
        top: 0px;
        
        // display: inline-block;
        background-color: #FFF;
        flex: 0 0 calc(100% + 4vw);
        margin-bottom: 30px;
        margin-top: -10px;

        transition: .3s all linear;
        
        @include rpmin(lg){
            top: 50px;
            flex: 0 0 226px;
            height: 100%;
            padding-right: 20px;
            
            margin: 0;
        }
        
        ul{
            list-style-type: none;
            @include rpmax(lg){
                display: flex;
                padding: 0px 2vw 10px 2vw;
                @include spGap(24px, 10px);
            }
            &>li{
                @include rpmin(lg){
                    padding: 8px 0;
                }
                &>a{
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 12px;

                    text-transform: uppercase;
                    color: #000;

                    position: relative;
                    @extend .hoverUnder;
                }

                &.active{
                    &>a{
                        color: var(--cl_main, currentColor);
                    }
                }


            }
        }
    }


    &-wrapper{
        flex: 0 0 100%;
        @include rpmin(md){
            // flex: 0 0 calc(100% - 240px);
            flex: 0 0 100%;
        }
        @include rpmin(lg){
            // flex: 0 0 calc(100% - 220px);
            flex: 1;
        }

        // @include rpmin(xl){
        //     flex: 0 0 calc(81.73% - 220px);
        // }

        ul{
            list-style-type: none;
        }

        .loadmore{
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid var(--cl_underl);
        }
    }

    .box-news{
        &:not(:first-child){
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid var(--cl_underl);
        }

        @include rpmin(md){
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            

            &:not(:first-child){
                padding-top: 24px;
                margin-top: 20px;
                border-top: 1px solid var(--cl_underl);
            }
        }

        .thumbnail{
            @include rpmin(md){
                flex: 0 0 49%;
            }
            display: block;
            aspect-ratio: 105/62;
            background-color: var(--cl_gray);

            img{
                display: block;
                height: 100%;
            }
        }

        .content{
            margin-top: 8px;
            @include rpmin(md){
                flex: 1;
                display: flex;
                flex-direction: column;
                padding-left: 16px;
            }

            time{
                flex: 1;

                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
            }

            &>h2, &>h3, &>h4, &>h5{
                margin-top: 8px;

                // font-size: 20px;
                // @include title(20px);
                // font-weight: 600;
                // line-height: 1.2;

                @include title(28px);
                @include bold;
                color: rgba(0,0,0,.75);
            }

            &-desc{
                margin-top: 10px;
                font-weight: 400;
                // font-size: 14px;
                // line-height: 20px;

                font-size: 16px;
                line-height: 1.42857142857;
                // line-height: 156%;

                // letter-spacing: 1px;
                color: var(--cl_txt);
            }
        }
    }

    &-media{
        flex: 0 0 100%;
        @include rpmin(md){
            // flex: 0 0 calc(100% - 240px);
            flex: 0 0 100%;
        }
        @include rpmin(lg){
            flex: 1;
        }
        ul{
            list-style-type: none;
        }

        .media-item{
            a{
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding: 29.81%;
                &>*{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0;
                    cursor: none;
                    pointer-events: none;
                    transition: .3s opacity ease-out;


                }
                .iconplay{
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 36px;
                    height: 36px;
                    transform: translate(-50%, -50%);
                    object-fit: contain;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: .3s all ease-out;

                    @include rpmin(md){
                        width: 50px;
                        height: 50px;
                    }
                    user-select: none;
                    pointer-events: none;
                }

                &:hover{
                    &::after{
                        opacity: .2;
                    }
                    .iconplay{
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
            }
        }

        .loadmore{
            margin-top: 30px;
            padding-top: 30px;
        }
    }



    &__title{
        display: block;
        text-align: center;
        
        margin-top: 0px;
        margin-bottom: 40px;

        @include rpmin(md){
            margin-top: 16px;
            margin-bottom: 56px;
        }

        &>*{
            font-size: 16px;
            @include bold;
            line-height: 1.4;
        }
    }

    &--detail{
        width: 100%;
        margin: 0 auto;
        @include space(padding-bottom, 100px);

        @include rpmin(lg){
            width: 83.2%;
        }

        .article{
            &-header{
                width: 100%;
                @include rpmin(lg){
                    width: 69%;
                }
                color: #000;
                
                h1{
                    // font-size: 38px;
                    @include title(38px);

                    font-weight: 700;
                    line-height: 136%;
                    text-transform: uppercase;

                    color: rgba(0,0,0,.75);
    
                }
                time{
                    display: block;
                    margin-top: 24px;

                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;    
                }
            }

            section{
                margin-top: 24px;
            }

            &-footer{
                margin-top: 30px;
            }
        }        
    }
}

.title-fullwidth{
    .article-header{
        width: 100%;
    }
}