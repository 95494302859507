.ct-1{
    margin-top: 30px;
    @include rpmin(md){
        margin-top: 50px;
    }
    &-wrapper{
        width:100%;
        
        @include rpmin(lg){
            width: 66.66667%;
            margin: 0 auto;
        }
    }
    &__name{
        &>*{
            font-weight: 700;
            @include title(38px);
            line-height: 136%;
            text-transform: uppercase;
            color: var(--cl_txt);
        }
    }   
    &-information{
        margin-top: 24px;

        table{
            tr{
                // @include rpmax(md){
                //     display: block;
                // }
                td:first-child{
                    width: auto;
                    min-width: 120px;
                    
                    @include rpmin(md){
                        width: 50%;
                        min-width: 50%;
                    }
                }

                // td:not(:first-child){
                //     padding-left: 10px;
                // }

                td{
                    // display: inline-flex;
                    align-items: center;
                    min-height: 38px;
                    ul{
                        list-style-type: none;
                        display: flex;
                        flex-wrap: wrap;
                        
                        @include spGap2(10px, 24px);
                        
                        &>li>a{
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

.ct-2{
    // padding: 100px 0;
    @include space-section(padding, 100px);

    &-wrapper{
        width:100%;
        
        @include rpmin(lg){
            width: 66.66667%;
            margin: 0 auto;
        }
    }

    &__name{
        &>*{
            font-weight: 700;
            @include title(38px);
            line-height: 136%;
            text-transform: uppercase;
            color: var(--cl_txt);
        }
    }

    &-form{
        margin-top: 20px;
    }

    &__map{
        @include space(margin-top, 100px);
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        position: relative;

        &>*{
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            min-height: 40vh;
            max-height: 60vh;
        }
    }
}