@mixin ts52{
    &>*{
        @include title(52px, !important);
        @include bold;
        color: var(--cl_ttl);
    }
}
@mixin ts38{
    &>*{
        @include title(38px, !important);
        @include bold;
        color: var(--cl_ttl);
    }
}
@mixin cs16{
    @include content(16px, !important);
    line-height: 156%;
    color: var(--cl_txt);
}

.title52{@include ts52}
.title38{@include ts38}
.content16{@include cs16}

.ts52px{@include title(52px)};
.cs20px{@include content(20px)};
.cs16px{@include content(16px)};
.cs14px{@include content(14px)};
.cs13px{@include content(13px)};


$arrSpace: 100px, 60px;

@each $space in $arrSpace {
    .p-#{$space}{
        @include space-section(padding,$space);
    }
    .pt-#{$space}{
        @include space(padding-top,$space);
    }
    .pb-#{$space}{
        @include space(padding-bottom,$space);
    }
    .m-#{$space}{
        @include space-section(margin,$space);
    }
    .mt-#{$space}{
        @include space(margin-top,$space);
    }
    .mb-#{$space}{
        @include space(margin-bottom,$space);
    }
}

