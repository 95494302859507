
@keyframes showHomeScreen{
    to{
        transform: translate(0%, 0%);
    }
}

.promotion-app{
    position: fixed;
    background-color: #E41D01;
    width: 80px;
    padding-top: 80px;
    height: auto;

    // height: 233px;
    
    top: auto;
    bottom: 200px;
    left: calc((100vw - #{var(--wContainer)}) / 2);
    // transform: translate(calc(-100% - 2vw), 0%);


    border: none;
    border-radius: 100%;

    z-index: 99998;
    display: block;

    animation: showHomeScreen 1s linear forwards;

    a{
        color: currentColor;
    }
    
    .wrapper-app{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        color: #FFF;
        // text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;

        width: 100%;
        height: 100%;
        padding: 14px 9px;
        border-radius: 100%;

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        text-align: center;

        cursor: pointer;

        .img-app{
            display: block;
            width: 22px;
            height: 20px;
            // border-radius: 100%;
            // background-color: #E41D01;

            overflow: hidden;

            // padding: 16px;

            &>*{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: top center;
            }
        }

        &>*:not(:first-child){
            margin-top: 5px;
        }
    }

    .close-app{
        position: absolute;
        top: 100%;
        left: 50%;

        width: 16px;
        height: 16px;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        display: block; 
        box-shadow: 0 4px 4px rgba(0,0,0,.25);
        
        cursor: pointer;
        z-index: 1;
        
        transition: .2s all;
    
        &:hover{
            transform: scale(1.2);
        }

        &::before{
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after{
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::before, &::after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 0;
            border-top: 1px solid #000;
            border-radius: 2px;
        }
    }

    // @media screen and (min-width: 768px) {
    //     width: 184px;
    //     border-radius: 20px;

    //     .wrapper-app{
    //         font-size: 18px;
    //         padding: 20px;

    //         .img-app{
    //             width: 105px;
    //             height: 105px;
    //         }

    //         &>*:not(:first-child){
    //             margin-top: 20px;
    //         }
    //     }

    //     .close-app{
    //         top: 10px;
    //         right: 10px;
    //         width: 20px;
    //         height:  20px;

    //         &::before, &::after{
    //             width: 8px;
    //         }
    //     }
    // }
}

