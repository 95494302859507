.banner{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;
    transition: 0.6s transform cubic-bezier(0.47, 0, 0.745, 0.715);

    &-item{
        width: 100%;
        height: 100%;
        position: relative;
        color: var(--cl_w, #FFF);
        --spBContent: 40px;

        .thumbnail{
            display: block;
            width: 100%;
            height: 100%;
            padding-bottom: 6px;
        }

        .content{
            position: absolute;
            bottom: 6px;
            left: 0;
            width: 100%;
            
            &-wrapper{
                position: relative;
                z-index: 2;
                width: var(--wContainer);
                padding: 40px 0;
                margin: auto;
            }

            &__pages{
                margin-bottom: 7px;
                
                @include regular;
                color: currentColor;
            }

            &__main{
                &>*{
                    // font-size: 52px;
                    color: currentColor;
                    line-height: 138%;
                }
            }

            &-bottom{
                display: flex;
                // margin-top: 6px;
                @include spGap2(16px, 45px, 6px);
                
                @include rpmin(lg){
                    @include spGap2(16px, 45px, -8px);
                    // margin-top: -8px;
                }
                
                p{
                    color: currentColor;
                    @include regular;
                    line-height: 138%;

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            &-view{
                position: absolute;
                bottom: 30vh;
                left: 50%;
                transform: translate(-50%, -100%);
                
                background-color: rgba(217, 217, 217, 0.34);
                border: 1px solid rgba(255, 255, 255, 0.59);

                border-radius: 50%;
                padding: 16px 20px;
                min-width: 158px;
                text-align: center;

                font-weight: 400;
                font-size: 14px;
                line-height: 110%;

                color: currentColor;
                
                
                @include rpmin(lg){
                    border: none;
                    background: none;

                    top: auto;left:auto;
                    bottom: 22px;
                    right: 0;
                    display: inline-block;
                    text-align: right;

                    min-width: auto;
                    border-radius: 0;

                    padding: 0;

                    font-weight: 500;
                    font-size: 20px;
                    line-height: 1;
                }
            }

            &::after{
                position: absolute;
                z-index: 1;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                // background: linear-gradient(352.89deg, rgba(0, 0, 0, 0.49) 6.05%, rgba(69, 69, 69, 0) 94.99%);
                // background: linear-gradient(0deg, rgba(0, 0, 0, 0.49) 0%, rgba(69, 69, 69, 0) 100%);
                
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(69, 69, 69, 0) 100%);
                // background: linear-gradient(0deg, rgba(0, 0, 0, 0.49) 42.81%, rgba(69, 69, 69, 0) 115.67%);
            }
        }
    }

    .scrollToExplore{
        --cl_explore: #FFF;
    }

    .swiper-scrollbar{
        background-color: #FFF;
    }

    .swiper-scrollbar-lock{
        display: block !important;
    }

    .swiper-button{
        &-next, &-prev{
            display: block !important;
            --sPos: 0%;
            border: none;
            background-color: #9E9E9E;
            top: var(--hHeader, 0px);
            // bottom: 184px;

            height: calc(100% - #{var(--hHeader, 0px)} - 175px);
            width: 16vw;
            
            @include rpmin(md){
                height: calc(100% - #{var(--hHeader, 0px)} - 184px);
                width: 32vw;
            }

            transform: none;
            // transform: translateY(-50%);
            opacity: 0;

            margin-top: 0;
            border-radius: 0;

            transition: .2s opacity linear;

            &:hover{
                opacity: .05;
            }

            &::after{
                content: none;
            }
        }

        &-prev{
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
        &-next{
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
    }
}