@import "base/normalize";

@import "libraries/loading.scss";

@import "base/fonts";

@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/responsive";
@import "abstracts/functions";

@import "base/reset";
@import "base/constants";

@import "base/utilities";
@import "base/animations";

@import "base/responsive";


// libraries
@import "libraries/promotionApp.scss";
@import "libraries/fancy-box.scss";
@import "libraries/overflow";
// @import "libraries/pagination.scss";
@import "libraries/swiper.scss";


// component

// layout
@import "layout/banner.scss";
@import "layout/header.scss";
@import "layout/footer.scss";

@import "layout/form";
@import "layout/table";


// page

@import "pages/index";
@import "pages/works";
@import "pages/contact";
@import "pages/about";
@import "pages/news";
@import "pages/404";


// main{
//     --ptMain: 70px;
//     @include rpmin(lg){
//         --ptMain: 108px;
//     }
//     padding-top: var(--ptMain);
// }


main{
    min-height: calc(100vh - 68px);
    @include rpmin(md){
        min-height: calc(100vh - 92px);
    }
}

.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}