.ov-hide {
    overflow: hidden;
}
.m-auto {
    margin-left: auto;
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}
.mt-auto {
    margin-top: auto;
}

.thin {
    @include thin(true);
}
.extra-light {
    @include extra-light(true);
}
.light {
    @include light(true);
}
.regular {
    @include regular(true);
}
.normal {
    @include normal(true);
}
.medium {
    @include medium(true);
}
.semi-bold {
    @include semi-bold(true);
}
.bold {
    @include bold(true);
}
.extra-bold {
    @include extra-bold(true);
}
.ultra-bold {
    @include ultra-bold(true);
}

.m-c {
    margin-left: auto;
    margin-right: auto;
}

.df-cc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tc {
    text-align: center;
}
.tl {
    text-align: left;
}
.tr {
    text-align: right;
}

.uppercase {
    text-transform: uppercase !important;
}
.bold {
    font-weight: bold !important;
}

.italic {
    font-style: italic !important;
}

.d-none {
    display: none !important;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scrollgsap {
    position: relative;
    transition:
        top 0.5s ease,
        opacity 0.1s ease;
    top: 150px;
    opacity: 0;

    &.gsap-loaded {
        top: 0;
        opacity: 1;
    }
}

.f-align-center {
    align-items: center;
}

.obj-contain {
    object-fit: contain;
}
.obj-cover {
    object-fit: cover;
}

// .of-scroll{
//     /* width */
//     &::-webkit-scrollbar {
//         width: 3px;
//         height: 3px;
//         border-radius: 3px;
//     }

//     /* Track */
//     &::-webkit-scrollbar-track {
//         background: #EAEAEA;
//     }

//     /* Handle */
//     &::-webkit-scrollbar-thumb {
//         background: var(--cl_main);
//     }

//     /* Handle on hover */
//     &::-webkit-scrollbar-thumb:hover {
//         background: #5D5D5D;
//     }
// }

.appear-box {
    animation: appearBox 0.7s linear;
}

@for $i from 1 through 10 {
    .plc-#{$i} {
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@each $key, $val in $color {
    .cl-#{$key} {
        color: #{$val};
    }
}

.writeux {
    font-family: map-get($family, primary), sans-serif !important;
    color: currentColor;

    font-size: 16px;
    line-height: 156%;
    color: var(--cl_txt, #000);

    &.justify {
        text-align: justify;
    }

    .float-left {
        float: left;
    }

    .float-right {
        float: right;
        width: 120px;
    }

    .clear-fix {
        clear: both;
    }

    h2 {
        font-size: inherit;
    }
    h3 {
        font-size: 16px;
    }
    h4 {
        font-size: 14px;
    }
    h5 {
        font-size: 13px;
    }
    h5 {
        font-size: 12px;
    }

    div[class|="col"] {
        display: flex;
        flex-direction: column;
        a[data-fancybox] {
            display: block;
            flex: 1;

            img {
                width: 100%;
                height: 100%;
            }
        }
        img {
            display: block;
            flex: 1;

            &.f-auto {
                flex: 0 0 auto;
            }

            &.full-w {
                width: 100%;
            }
        }
    }

    .red,
    .cl-main,
    .cl-red {
        color: var(--cl_main);
    }

    p {
        @include content(16px);
        line-height: 156%;

        &.inline-block {
            display: inline-block;
        }
        &.inline {
            display: inline;
        }
    }

    br {
        &.cp {
            display: none;
            @include rpmin(lg) {
                display: block;
            }
        }

        &.tb {
            display: none;
            @include rpmin(md) {
                display: block;
            }
        }

        &.mb {
            display: none;
            @include rpmin(md) {
                display: block;
            }
        }
    }

    span {
        &.block {
            display: block;
        }
        &.inline-block {
            display: inline-block;
        }
    }

    ul,
    ol {
        padding-left: 1.4rem;

        &.style-none {
            list-style-type: none;
            padding-left: 0;
        }

        &.tab-left {
            margin-left: 0;
            @include rpmin(md) {
                margin-left: 2rem;
            }
        }
    }

    img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        @include rpmax(md) {
            width: 100% !important;
        }

        &.obj-contain {
        }
        &.obj-cover {
            width: 100%;
        }
    }

    a {
        display: inline;
        color: var(--cl_main);

        &:hover {
            color: var(--cl_main);
            text-decoration: underline;
        }
    }

    a[data-fancybox] {
        display: inline-block;
    }

    figure {
        figcaption {
            font-size: 14px;
            font-style: italic;
            color: var(--cl_gray);

            text-align: center;
            font-style: italic;
        }
    }

    .l3image {
        padding: 0;
        margin: 0;

        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-items: stretch;

        position: relative;
        width: 100%;

        li:nth-of-type(1) {
            flex: 0 0 69.5%;
        }
        li:nth-of-type(2) {
            position: absolute;
            height: calc(50% - 2px);
            top: 0;
            left: calc(69.5% + 4px);
            width: calc(30.5% - 4px);
        }
        li:nth-of-type(3) {
            position: absolute;
            top: calc(50% + 2px);
            height: calc(50% - 2px);

            left: calc(69.5% + 4px);
            width: calc(30.5% - 4px);
        }

        li > img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    p.caption {
        font-size: 14px;
        line-height: 2;
        font-style: italic;
        color: var(--cl_gray);

        text-align: center;
        font-style: italic;
    }
}

// .fx{display: flex !important}
// .fx-c{
//     justify-content: center !important;
//     align-items: center!important;
// }
// .fx-wrap{flex-wrap: wrap !important}
// .fx-col{flex-direction: column!important}
// .fx-row{flex-direction: row !important}

// .jc-c{justify-content: center !important}
// .jc-sb{justify-content: space-between !important}
// .jc-fs{justify-content: flex-start !important}
// .jc-fe{justify-content: flex-end !important}

// .ai-c{align-items: center !important}
// .ai-s{align-items: stretch !important}
// .ai-sb{align-items: space-between !important}
// .ai-fs{align-items: flex-start !important}
// .ai-fe{align-items: flex-end !important}

// @each $key, $size in $minWidth {
//     @media (min-width: $size){
//         .tc-#{$key}{text-align: center !important}
//         .tl-#{$key}{text-align: left!important}
//         .tr-#{$key}{text-align: right !important}

//         .jc-c-#{$key}{justify-content: center !important}
//         .jc-sb-#{$key}{justify-content: space-between !important}
//         .jc-fs-#{$key}{justify-content: flex-start !important}
//         .jc-fe-#{$key}{justify-content: flex-end !important}

//         .ai-c-#{$key}{align-items: center !important}
//         .ai-s-#{$key}{align-items: stretch !important}

//         .ai-sb-#{$key}{align-items: space-between !important}
//         .ai-fs-#{$key}{align-items: flex-start !important}
//         .ai-fe-#{$key}{align-items: flex-end !important}

//         .fx-wrap-#{$key}{flex-wrap: wrap !important}
//         .fx-col-#{$key}{flex-direction: column !important}
//         .fx-row-#{$key}{flex-direction: row !important}
//     }
// }
