table{
    display: table;
    width: 100%;
    tr{
        @include rpmax(md){
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
        }
        &>td{
            vertical-align: top;

            font-weight: 400;
            font-size: 16px;
            line-height: 156%;
            color: var(--cl_txt);

            padding: 6px 12px 6px 0;

            ul{
                list-style-type: none;
            }

            a{
                color: var(--cl_main);

                &:hover{
                    color: var(--cl_main);
                    text-decoration: underline;
                }
            }

            .icon{
                // --topShare: 0px;    
                // a{
                //     color: #2F2F2F;
                //     width: 14px;
                //     height: 14px;
                // }
                &>ul{
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;
                    
                    @include spGap2(10px, 24px);
                    &>li>a{
                        display: flex;
                        align-items: center;

                        color: #2F2F2F;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
        &>td:first-child{
            font-weight: 500;

            width: 158px;
            flex: 0 0 158px;
            min-width: 18%;
            color: rgba(0,0,0,.75);


            &[colspan]{
                width: auto;
                flex: 0 0 auto;
                max-width: 100%;
            }

            @include rpmin(md){
                width: 202px;
                flex: 0 0 202px;
            }
        }

        border-bottom: 1px solid var(--cl_underl);
        &:first-child{
            td{
                @include rpmin(md){
                    padding-top: 0;
                }

            }
        }
    }
}