.works-section{
    color: var(--cl_txt);
    @include rpmin(md){
        padding-top: 20px;
    }

    &--sticky{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--cl_w);

        transition: .3s all linear;
    }

    &-container{
        position: relative;

        @include rpmax(md){
            width: 100%;
        }
    }

    &--categories{
        display: block;

        @include rpmin(md){
            width: 100%;
            margin: 0 auto;
            padding: 0 120px;
        }

        .projecttype{
            @include rpmax(md){
                display: flex;
                justify-content: space-between;

                color: rgba(255,255,255,.75);
                background-color: #000;

                position: relative;
                // @include spGap2(20px, 34px);

                .name{
                    display: none;
                    @media screen and (min-width: 410px){
                        display: block;
                        display: none;

                        padding: 18px 0vw 18px 2vw;
                        flex: 0 0 30%;
    
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }

                &-opt{
                    flex: 1;
                    padding: 12px 2vw 12px 2vw;
                    @media screen and (min-width: 410px){
                        padding: 18px 2vw 18px 2vw;
                    }

                    display: flex;
                    justify-content: space-between;

                    font-weight: 700;
                    font-size: 16px;

                    .icon{
                        transform: rotate(180deg);
                        transition: .16s all linear;

                        svg{
                            path{
                                fill: rgba(255,255,255,.75);
                                stroke: rgba(255,255,255,.75);
                            }
                        }
                    }
                }

                &.is-showed{
                    .projecttype-opt{
                        .icon{
                            transform: rotate(0);
                        }
                    }
                    .projecttype-ls{
                        opacity: 1;
                        visibility: visible;
                        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                    }
                }
            }

            @include rpmin(md){
                .name{
                    display: none;
                }
                &-opt{
                    display: none;
                }
            }

            &-ls{
                list-style-type: none;

                @include rpmax(md){
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    width: 100%;
                    left: 0;
    
                    background-color: #000;
                    border-top: 1px solid var(--cl_gray);
    
                    padding: 10px 2vw 10px 2vw;

                    // @media screen and (min-width: 410px){
                    //     padding: 10px 2vw 10px calc(30% + 2vw);
                    // }

                    list-style-type: none;
                    &>li>a{
                        display: inline-block;
    
                        font-weight: 700;
                        font-size: 16px;
    
                        padding: 6px 2vw 6px 0;
    
                        text-transform: uppercase;
                    }

                    &>li.active{
                        display: none;
                    }

                    opacity: 0;
                    visibility: hidden;
                    transition: .16s all ease-in-out;
                    clip-path: polygon(0 0, 100% 0, 50% 50%, 0% 100%);
                }

                @include rpmin(md){
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    
                    padding: 0px 0 20px 0;
                    @include spGap2(20px, 40px, 20px);
        
                    &>li>a{
                        display: block;

                        font-weight: 700;
                        font-size: 14px;
        
                        text-transform: uppercase;
                        text-align: center;
        
                        position: relative;
                        transition: .16s all ease-in-out;

                        &::after{
                            --cl_main: #{map-get($color, main)};
                            // content: "";
                            position: absolute;
                            bottom: 0%;
                            left: 0;
                            width: 0%;
                            height: 0;
                            border-bottom: 1px solid var(--cl_main, #000);
                            transition: .16s width linear;
                        }
                    
                        &:hover, &:focus{
                            color: var(--cl_main);
                            &::after{
                                width: 100%;
                            }
                        }
                    }
        
                    &>li.active>a{
                        color: var(--cl_main);
                        &::after{
                            width: 100%;
                        }
                    }

                }

            }
        }

        
    }

    &__galary{
        display: none;
        @include rpmin(md){
            cursor: pointer;
    
            display: flex;
            align-items: center;
            flex-wrap: wrap;
    
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
    
            max-width: 120px;
            z-index: 1;
    
            p, span{
                display: none;
    
                font-weight: 500;
                font-size: 14px;
                line-height: 12px;
            }
    
            &[data-type="galary"]{
                [data-select="galary"]{
                    display: flex;
                    align-items: center;
                }
            }
    
            &[data-type="list"]{
                [data-select="list"]{
                    display: flex;
                    align-items: center;
                }
            }
    
            img, svg{
                margin-left: 4px;
            }
        }
    }

    &>.works-section_galary{
        position: fixed;
        top: 50%;
        right: calc((100vw - #{var(--wContainer)}) / 2);

        transform: rotate(90deg) translate(0%, calc(-100% - 20px));
        transform-origin: right;
    }

    &--projects{
        margin-top: 40px;
        // padding-bottom: 100px;

        position: relative;

        // background-image: url('/assets/icons/icon-bg-none.svg');
        background-image: url('/Content/resources/assets/icons/icon-bg-none.svg');
        background-repeat: repeat-y;
        background-size: 120%;

        ul{
            width: 100%;
        }


        .project-box{
            opacity: 0;
            transform: translateY(40px);
            transition: 0.6s opacity ease,
                        0.6s transform ease;

            &-thumb{
                // background-color: var(--cl_grayl);
                display: block;
                a{
                    display: block;
                    overflow: hidden;
                }
                img{
                    display: block;
                    transition: .6s transform linear;
                }
            }
            .project-box-thumb{
                &:hover{
                    img{
                        transition-delay: .4s;
                        transform: scale(1.08);
                    }
                }
            }
            
            // &__name{
            //     &:hover{
            //         &>*{
            //             color: #000 !important;
            //         }
            //     }
            // }
        }

        @include rpmax(md){
            ul{
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + 10px);

                @include spGap2(40px, 10px);

                &>li{
                    width:100%;
                }
    
                .project-box{
                    display: flex;
                    flex-direction: column;
                    height:100%;

                    &__cate{
                        font-weight: 500;
                        @include content(12px);

                        text-indent: 2px;
                        text-transform: uppercase;
                        // color: var(--cl_gray);
                        color: #8D8D8D;
                    }

                    &__name{
                        margin-top: 10px;
                        &>*{
                            @include title(52px, !important);
                            @include bold;
                            // color: var(--cl_ttl);
                            color: rgba(0,0,0,.75);

                            line-height: 136%;
                        }
                    }

                    &-content{
                        flex: 1;
                        color: #7C7C7C;
                    }

                    &__desc{
                        margin-top: 12px;
                        color: #7C7C7C;
                    }

                    &-thumb{
                        width: calc(100% + 4vw);
                        margin-left: -2vw;
                        margin-top: 12px;

                        a{
                            position: relative;
                            width: 100%;
                            display: block;
                            height: 0;
                            padding-top: 59.046%;
                            overflow: hidden;
                            img{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: block;

                                // display: block;
                                // // aspect-ratio: 6/5;
                                // aspect-ratio: 105/62;

                            }
                        }

                        // img{
                        //     aspect-ratio: 105/62;
                        // }
                    }


                }
            }
        }

        @include rpmin(md){
            &[data-type="galary"]{
                ul{
                    list-style-type: none;
                    .project-box{
                        &__cate{
                            font-weight: 500;
                            // font-size: 12px;
                            @include content(12px);
    
                            text-indent: 2px;
                            text-transform: uppercase;
                            // color: #9E9E9E;
                            // color: var(--cl_gray);
                            color: #8D8D8D;
                        }
            
                        &__name{
                            &>*{
                                @include title(52px, !important);
                                @include bold;
                                // color: var(--cl_ttl);
                                color: rgba(0,0,0,.75);
                                line-height: 136%;
                            }
                        }
                        &__desc{
                            color: #7C7C7C;
                        }
                    }
        
                    &>li{
                        &:first-child{
                            margin-top: 0 !important;
                        }

                        &:nth-of-type(3n + 1){
                            // margin-top: 8px;
                            .project-box{
                                --wContentBox: calc(50% - 15px);
                                margin-top: 48px;
                                @include rpmin(lg){
                                    // margin-top: 72px;
                                    margin-top: 108px;
                                }
                                @include rpmin(lg){
                                    --wContentBox: calc(50% - 26px);
                                }
                                &__cate, &__name{
                                    width: var(--wContentBox);
                                }
                                &__desc{
                                    --wContentBox: calc(50% - 26px);
                                    width: var(--wContentBox);
                                    margin-top: 4px;
                                }   
                                
                                &-thumb{
                                    margin-top: 24px;
                                    width: 100%;
                                    a{
                                        position: relative;
                                        display: block;
                                        width:100%;
                                        height: 0;
                                        padding-top: 43.7%;
                                    }
                                    
                                    img{
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;

                                        display: block;
                                        // aspect-ratio: 16/7;
                                        // aspect-ratio: 16/9;
                                    }
                                }
                            }

                            &:first-child{
                                .project-box{
                                    --wContentBox: 62%;
                                    margin-top: 0;
                                }
                            }
                        }
                        &:nth-of-type(3n + 2){
                            margin-top: 48px;
                            @include rpmin(lg){
                                // margin-top: 72px;
                                margin-top: 108px;
                            }
                            .project-box{
                                display: flex;
                                flex-direction: row-reverse;
                                // @include spGap(52px);
                                &>*{
                                    flex: 1;
                                }
        
                                &__name{
                                    margin-top: 2px;
                                }
        
                                &__desc{
                                    margin-top: 2px;
                                    padding-bottom: 40px;
                                }
        
                                &-thumb{
                                    width: 100%;
                                    a{
                                        position: relative;
                                        display: block;
                                        width:100%;
                                        height: 0;
                                        padding-top: 75%;
                                    }
                                    
                                    img{
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;

                                        display: block;
                                        // aspect-ratio: 4/3;
                                        // aspect-ratio: 16/9;
                                    }
                                }
                                &-content{
                                    margin-left: 30px;
                                    
                                    @include rpmin(lg){
                                        margin-left: 52px;
                                    }

                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                }
                            }
                        }
                        
                        &:nth-of-type(3n + 3){
                            margin-top: 48px;
                            @include rpmin(lg){
                                // margin-top: 72px;
                                margin-top: 108px;

                            }
                            .project-box{
                                display: flex;
                                flex-direction: row;
                                // @include spGap(52px);
                                &>*{
                                    flex: 1;
                                }
        
                                &__name{
                                    margin-top: 2px;
                                }
        
                                &__desc{
                                    margin-top: 2px;
                                    padding-bottom: 40px;
                                }
        
                                &-thumb{
                                    order: 2;
                                    width: 100%;
                                    a{
                                        position: relative;
                                        display: block;
                                        width:100%;
                                        height: 0;
                                        padding-top: 75%;
                                    }
                                    
                                    img{
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;

                                        display: block;
                                        // aspect-ratio: 4/3;
                                        // aspect-ratio: 16/9;
                                    }
                                }

                                &-content{
                                    order: 1;
                                    margin-right: 30px;
                                    
                                    @include rpmin(lg){
                                        margin-right: 52px;
                                    }

                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                }
                            }
                        }
        
                        
                        // &:nth-of-type(3n + 3){
                        //     padding-left: calc(50% + 15px);
                        //     @include rpmin(lg){
                        //         padding-left: calc(50% + 26px);
                        //     }
        
                        //     .project-box{
                        //         display: block;
        
                        //         &__name{
                        //             margin-top: 10px;
                        //         }
        
                        //         &__desc{
                        //             margin-top: 2px;
                        //             padding-bottom: 40px;
                        //         }
        
                        //         &-thumb{
                        //             img{
                        //                 display: block;
                        //                 aspect-ratio: 4/3;
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }
            }
    
            &[data-type="list"]{
                ul{
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;
                    
                    @include spGap2(40px, 10px);
                    width: calc(100% + 10px);
                    &>li{
                        width: 33.3333%;
                    }
        
                    .project-box{
                        height: 100%;
        
                        display: flex;
                        flex-direction: column;
                        &-content{
                            flex: 1;
                            margin-bottom: 8px;
                        }
        
                        &__desc{
                            display: none;
                        }
                        &__cate{
                            display: none;
                        }
        
                        &__name{
                            &>*{
                                font-weight: 600;
                                font-size: 20px;
                                // line-height: 20px;
            
                                text-align: center;
                                text-transform: uppercase;
                                // color: #000;
                                color: rgba(0,0,0,.75);

                            }
                        }
        
                        &-thumb{
                            // position: relative;
                            // width: 100%;
                            // display: block;
                            // height: 0;
                            // padding-top: 59.046%;
                            // a{
                            //     position: absolute;
                            //     top: 0;
                            //     left: 0;
                            //     width: 100%;
                            //     height: 100%;
                            //     display: block;

                            //     overflow: hidden;
                            // }
                            // img{
                            //     width: 100%;
                            //     height: 100%;

                            //     // display: block;
                            //     // // aspect-ratio: 6/5;
                            //     // aspect-ratio: 105/62;

                            // }
                            display: block;
                            width: 100%;
                            a{
                                position: relative;
                                width: 100%;
                                display: block;
                                height: 0;
                                padding-top: 59.046%;
                                overflow: hidden;

                                img{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: block;

                                    // display: block;
                                    // // aspect-ratio: 6/5;
                                    // aspect-ratio: 105/62;

                                }
                            }
                        }
                    }
                }
            }

            &[data-type=""]{
                visibility: hidden;
            }
        }
    }

    &--slider{
        position: relative;
        --sPos: 28px;
        @include rpmin(md){
            margin-top: 40px;
        }

        .swiper-selectSlider{
            // background-color: var(--cl_gray);
            
            width: calc(100% + 4vw);
            margin-left: -2vw;

            @include rpmin(md){
                width: 100%;
                margin-left: 0;
                // aspect-ratio: 16/9;
                // height: 670px;

                // .swiper-slide{
                //     cursor: zoom-in;

                //     &.swiper-slide-zoomed{
                //         cursor: move;
                //     }
                // }
            }

            .swiper-slide{
                // height: 52vh;
                aspect-ratio: 105/62;

                @include rpmin(md){
                    width: 100%;
                    height: auto;
                    aspect-ratio: 16/9;
                    // height: 670px;
                }

                a{                    
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .swiper-thumbSlider{
            display: none;
            @include rpmin(md){
                display: block;
                margin-top: 10px;
                width: 100%;
                height: 54px;

                .swiper-slide{
                    width: auto;
                    height: 100%;
    
                    img{
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                    }
                    position: relative;
                    &::after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        padding: 3px;
                        background-color: var(--cl_gray);
                        opacity: 0.5;
                        z-index: 1;
                        // mix-blend-mode: multiply;
                    }
    
                    &.is-actived{
                        border: 3px solid var(--cl_main);
    
                        &::after{
                            content: "";
                        }
                    }
                }
            }
        }

        .features{
            @include rpmax(md){
                display: flex;
                justify-content: flex-end;
                // margin-top: 12px;
                // @include spGap2(20px, 36px, 12px);

                &>*{
                    margin-left: 36px;
                    margin-top: 20px;
                }
            }
            
            @include rpmin(md){
                position: absolute;
                z-index: 11;
                top: 20px;
                right: var(--sPos);
            }

            &-item{
                display: block;
                width: 30px;
                max-height: 30px;
                aspect-ratio: 1/1;

                cursor: pointer;

                &>*{
                    display: block;
                    max-height: 100%;
                    object-fit: contain;
                }

                &:not(:first-child){
                    @include rpmin(md){
                        margin-top: 20px;
                    }
                    @include rpmax(md){
                        position: relative;
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -18px;
                            height: 100%;
                            width: 0;
                            border-left: 1px solid #E4E4E4;
                        }
                    }
                }

                svg{
                    pointer-events: none;
                    [fill]:not([fill="none"]){
                        fill: rgba(123, 123, 123, 1);
                    }
                    [stroke]:not([stroke="none"]){
                        stroke: rgba(123, 123, 123, 1);
                    }

                    @include rpmin(md){
                        [fill]:not([fill="none"]){
                            fill: #FFF;
                        }
                        [stroke]:not([stroke="none"]){
                            stroke: #FFF;
                        }

                    }
                }

                &.vr360{
                    img{
                        display: none;
                        @include rpmin(md){
                            display: block;
                        }
                    }

                    img.mb{
                        display: none;
                        @include rpmax(md){
                            display: block !important;
                        }
                    }
                }

            }
        }
    }

    &--detail{
        margin-top: 35px;
        @include rpmin(md){
            margin-top: 50px;
        }
        h1{
            font-weight: 700;
            line-height: 52px;
            // color: #000;
            color: rgba(0,0,0,.75);
            
            // font-size: 32px;
            
            // @include rpmin(md){
            //     font-size: 38px;
            // }

            @include title(38px);
        }
        // table{
        //     width: 100%;
        //     tr{
        //         td{
        //             font-weight: 400;
        //             font-size: 16px;
        //             line-height: 156%;
        //             color: var(--cl_txt);

        //             padding: 6px 12px 6px 0;

        //             ul{
        //                 list-style-type: none;
        //             }
        //         }
        //         td:first-child{
        //             width: 202px;
        //             min-width: 18%;
        //         }

        //         border-bottom: 1px solid var(--cl_underl);
        //     }
        // }

        .infor-ls{
            margin-top: 10px;
            table{
                max-width: 100%;
                overflow: hidden;
            }
        }
    }
}