form{
    
    display: flex;
    flex-wrap: wrap;
    
    width: calc(100% + 20px);
    @include spGap(20px);

    .group-input{
        display: flex;
        flex-direction: column;
        width: 100%;
        @include rpmin(md){
            width: 50%;
        }
        
        label{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: var(--cl_txt);

            span{
                margin-left: 4px;
                color: var(--cl_main);
            }
        }

        input, textarea{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            border-radius: 0 !important;

            display: block;
            width: 100%;
            resize: none;

            border: none;
            border-bottom: 1px solid #3C3C3C;
            
            color: var(--cl_txt);

            font-size: 15px;
            padding-top: 4px;
            
        }

        .enterinput{
            position: relative;
            input:valid{
                & ~ .rstext{
                    display: flex;
                }

                padding-right: 18px;
            }
            
            .rstext{
                position: absolute;
                top: 50%;
                right: 0;
                width: 12px;
                height: 100%;
                z-index: 1;

                transform: translateY(-50%);

                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                display: none;

                &::before, &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 0;
                    border-bottom: 2px solid var(--cl_txt);
                }
                &::before{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &::after{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    .full{
        width: 100%;
    }

    .group-submit{
        width: 100%;

        input, button{
            background: none;
            outline: none;
            border: none;

            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            border-radius: 0 !important;

            background-color: #000;
            color: #FFF;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            text-transform: uppercase;

            padding: 8px 20px;

            min-width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            transition: .16s all ease-in;

            &:hover, &:focus{
                background-color: var(--cl_main);
            }


        }
    }
}