.footer{
    border-top: 1px solid var(--cl_underl);
    background-color: var(--cl-w, #FFF);

    &-logo{
        display: block;
        width: 174px;
    }

    &-top{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 40px 0 30px 0;
        
        @include rpmin(md){
            flex-direction: row;
            // padding: 55px 28px 26px 5px;
            padding: 30px 28px 26px 0px;
        }

        &>*{
            &:not(:first-child){
                margin-top: 30px;
            }
        }
    }

    &-menu{
        flex: 1;
        display: flex;
        justify-content: center;
        
        ul{
            display: flex;
            list-style-type: none;
            
            @include spGap(23px);
            @include rpmin(lg){
                @include spGap(42px);
            }

            &>li>a{
                color: var(--cl_txt);
                font-weight: 600;
                font-size: 10px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                @include rpmin(md){
                    font-size: 13px;
                }
                
                position: relative;
                @extend .hoverUnder;
            }
        }
    }
    &-socials{
        ul{
            list-style-type: none;
            display: flex;
            @include spGap(38px);

            @include rpmin(md){
                @include spGap(16px);

            }
            @include rpmin(lg){
                @include spGap(38px);
            }
            &>li>a{
                display: block;
                transform-origin: center;
                transition: .16s width cubic-bezier(0.175, 0.885, 0.32, 1.275);
                svg{
                    transform-box: fill-box;
                    transition: .16s all linear;
                    
                    [fill], [stroke]{
                        transition: .16s all linear;
                    }
                }
                
                @include rpmin(md){
                    opacity: .7;
                }

                &:hover{
                    opacity: 1;
                    svg{
                        transform: scale(1.1);
                        [fill]:not([fill="none"]){
                            fill: var(--cl_main);
                        }
                        [stroke]:not([stroke="none"]){
                            stroke: var(--cl_main);
                        }
                    }

                }
            }
        }
    }

    &-copyright{
        background-color: var(--cl_gray);

        text-align: center;
        padding: 8px;

        p{
            color: var(--cl_w);

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 2;

            a{
                display: inline-block;

                color: currentColor;
                text-decoration: underline;

                &:hover{
                    color: var(--cl_main);
                }
            }
        }
    }
}

// .footer{
//     padding: 60px 0 0px 0;
    
//     @include rpmin(lg){
//         padding: 100px 0 0px 0;
//     }

//     background-color: #F6F6F6;

//     ul{
//         list-style-type: none;
//     }

//     a:hover{
//         color: #8BC53F;
//     }

//     .logo{
//         width: 100%;
//         &>*{
//             height: 48px;
//             width: auto;
//             object-fit: contain;
//         }

//         @include rpmin(lg){   
//             width: auto;
//             &>*{
//                 height: 64px;
//             }
//         }
//     }

//     &-top{
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         flex-wrap: wrap;
//         gap: 40px;
//     }

//     &-navbar{
//         &>ul{
//             display: flex;
//             flex-wrap: wrap;
//             gap: 16px;
            
//             &>li{
//                 width: calc(50% - 8px);

//                 @include rpmin(sm){
//                     gap: 30px;
//                     width: auto;
//                 }



//             }
                
//             @include rpmin(lg){
//                 gap: 40px;

//             }
//         }
//     }

//     &-information{
//         margin-top: 40px;
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;
//         flex-direction: column;
//         gap: 30px;

//         @include rpmin(sm){
//             flex-direction: row;
//         }

//         .item{
//             width: 100%;
//             @include rpmin(sm){
//                 width: calc(50% - 15px);
//             }
//             @include rpmin(lg){
//                 width: auto;
//                 max-width: calc(25% - 23px);
//             }
//             &-name{
//                 font-size: 14px;
//                 @include bold;
//                 text-transform: uppercase;
//                 letter-spacing: 3px;
//                 @include rpmin(lg){
//                     letter-spacing: 4px;
//                 }
//             }

//             ul{
//                 margin-top: 10px;
//                 @include rpmin(lg){
//                     margin-top: 20px;
//                 }
//                 li{
//                     font-size: 13px;
//                     color: #000;

//                     line-height: 20px;
//                 }

//                 li + li{
//                     margin-top: 4px;
//                 }
//             }

//             form{
//                 margin-top: 10px;
//                 @include rpmin(lg){
//                     margin-top: 20px;
//                 }

//                 .group-input{
//                     min-height: 40px;
//                     position: relative;

//                     input:not([type="submit"]){
//                         width: 100%;
//                         height: 100%;

//                         min-height: 40px;
//                         font-size: 16px;
//                         line-height: 40px;

//                         color: #000;
//                         background-color: #FFF;
//                         border: 1px solid #000;

//                         padding: 3px 50px 3px 10px;

//                         &:focus{
//                             & ~ button[type="submit"]{
//                                 background-color: #8BC53F;

//                                 svg{
//                                     transform: rotate(-180deg);
//                                 }
//                             }
//                         }
//                     }

//                     input[type="submit"],
//                     button[type="submit"]{
//                         position: absolute;
//                         top: 0;
//                         right: 0;
//                         width: 40px;
//                         height: 100%;

//                         background-color: #000;
//                         transition: .3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);

//                         border-top: 1px solid #000;
//                         border-right: 1px solid #000;
//                         border-bottom: 1px solid #000;
//                         border-left: none;
//                         outline: none;
                        
//                         svg{
//                             transition: .6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
//                             *{
//                                 &[fill]:not([fill="none"]){
//                                     fill: #FFF;
//                                 }
//                                 &[stroke]:not([stroke="none"]){
//                                     stroke: #FFF;
//                                 }
//                             }
//                         }

//                         &:hover{
//                             background-color: #8BC53F;

//                             svg{
//                                 transform: rotate(-180deg);
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     &-copyright{
//         margin-top: 60px;
//         padding: 20px 0;
//         @include rpmin(lg){
//             margin-top: 80px;
//             padding: 30px 0;
//         }
//         border-top: 1px solid rgba(0, 0, 0, 0.05);
//         text-align: center;
//         line-height: 150%;

//         a{
//             display: inline-block;
//             &:hover{
//                 color: #8BC53F;
//             }
//         }
//     }
// }