.ab-section{
    margin-top: 60px;

    &-wrapper{
        width: 100%;
        margin: 0 auto;

        &.w2{
            width: 100%;
            margin-top: 40px;
            
            @include rpmin(lg){
                width: 83%;
                margin-top: 80px;
            }
        }
        &.w3{
            width: 100%;
            margin-top: 40px;
            
            @include rpmin(lg){
                width: 66.2%;
                margin-top: 80px;
            }
        }

        &>ul{
            list-style-type: none;
            &>li{
                display: flex;
                flex-wrap: wrap;

                .ab-section__sub{
                    flex: 0 0 100%;
                    max-width: 100%;
                    @include rpmin(md){
                        flex: 0 0 18%;
                        max-width: 18%;
                    }
                }

                .ab-section-content{
                    flex: 0 0 100%;
                    max-width: 100%;

                    margin-top: 20px;

                    @include rpmin(md){
                        margin-top: 0;
                        flex: 0 0 calc(100% - 18% - 53px);
                        max-width: calc(100% - 18% - 53px);
                    }
                }

                &:nth-of-type(1){
                    .ab-section-content{
                        position: relative;
                        padding-bottom: 20px;
                        @include rpmin(md){
                            padding-bottom: 48px;
                        }

                        &::after{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 92%;
                            height: 0;
                            border-bottom: 1px solid var(--cl_underl);
                        }
                    }
                }

                &:first-child{
                    margin-bottom: 30px;
                    @include rpmin(md){
                        margin-bottom: 60px;
                    }
                }
                &:not(:first-child){
                    margin-bottom: 40px;
                    @include rpmin(md){
                        margin-bottom: 80px;
                    }
                }
            }
        }
    }

    &__main{
        width: 100%;
        @include rpmin(md){
            width: 76%;
        }
        &>*{
            font-style: normal;
            font-weight: 700;
            line-height: 136%;
            @include title(38px);
        }
    }

    &--profile{
        margin-top: 36px;

        .ab-section__sub{
            text-align: right;
            margin-right: 53px;

            @include rpmax(md){
                text-align: left;
                // &>*{
                //     text-transform: capitalize !important;
                // }
            }
        }
    }

    &-group{
        // margin-top: 44px;
        // margin-top: 80px;
        @include space(margin-top, 100px);

        .ab-section__sub{
            text-align: center;
        }

        &:not(:first-child){
            @include space(margin-top, 100px);
        }
    }

    &__sub{
        // &>*{
        //     // @include content(18px);
        //     // font-size: 18px;
        //     @include title(28px);
        //     @include bold;

        //     color: rgba(0,0,0,.75);

        //     &:not(:first-child){
        //         margin-top: 10px;
        //     }
        // }
        position: relative;
        z-index: 1;

        &>*{
            // @include content(18px);
            // font-size: 18px;
            @include title(28px);
            @include bold;
            color: rgba(0,0,0,.75);

            &:not(:first-child){
                margin-top: 4px;
                @include content(14px);
                font-weight: 400;
            }
        }
    }

    &-content{
        img{
            display: block;
            width: auto;
            max-width: 100%;
            margin-bottom: 20px;
            @include rpmin(md){
                margin-bottom: 42px;
            }
        }
        @include cs16;

        p{
            @include cs16;
        }

        table{
            tr{
                border-bottom: none;
            }
        }

        a{
            display: inline;
            color: var(--cl_main);
        
            &:hover{
                color: var(--cl_main);
                text-decoration: underline;
            }
        }

        .article-ls{
            display: flex;
            flex-wrap: wrap;
            @include spGap2(36px, 10px);

            @include rpmin(md){
                @include spGap2(40px, 10px);
            }
            @include rpmin(lg){
                // @include spGap2(59px, 20px);
                @include spGap2(59px, 12px);
            }
            @include rpmin(xl){
                // @include spGap2(59px, 49px);
                @include spGap2(59px, 12px);
            }

            a{
                &:hover{
                    text-decoration: none;
                }
            }

            .item{
                flex: 0 0 100%;
                
                @include rpmin(md){
                    flex: 0 0 50%;
                }
                @include rpmin(lg){
                    flex: 0 0 33.3333%;
                }

                display: flex;
                flex-direction: column;

                color: var(--cl_txt, #000);

                &__name{
                    order: 2;
                    flex: 1;
                    margin-top: 15px;

                    &>*{
                        font-weight: 400;
                        // font-size: 20px;
                        // @include content(18px);
                        @include cs16;

                        line-height: 1.4;
                    }
                }
                &__desc{
                    order: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    margin-top: 0px;

                    &>*{
                        // color: #7C7C7C;
                        color: rgba(0,0,0,.75);
                        font-weight: 600;
                        @include content(16px, !important);


                        line-height: 1.4;
                    }
                }
                &__year{
                    display: block;
                    // margin-top: 6px;
                    color: rgba(0,0,0,.75);
                    
                    // color: #7C7C7C;
                    font-weight: 600;
                    @include content(16px, !important);

                    line-height: 1.4;
                }
                
                &-thumb{
                    order: 3;
                    display: block;
                    margin-top: 15px;
                    // aspect-ratio: 4/3;
                    width: 100%;
                    height: 0px;
                    padding-top: 78.4%;
                    position: relative;
                    overflow: hidden;

                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        display: block;
                        margin: 0;

                        transition: .6s transform linear;
                    }
                }

                &:hover{
                    .item-thumb{
                        img{
                            transform: scale(1.08);
                        }
                    }
                }

            }

            .loadmore{
                padding-top:0;
                width: 100%;
                text-align: right;

                @include rpmax(md){
                    text-align: center;
                }
            }
        }
    }

    &-ls{
        // margin-top: 40px;

        display: flex;
        justify-content: center;

        flex-wrap: wrap;
        @include spGap2(40px, 20px, 40px);
        @include rpmin(md){
            @include spGap2(40px, 20px, 40px);
        }
        @include rpmin(lg){
            @include spGap2(57px, 57px, 40px);
        }

        .item{
            list-style-type: none;
            flex: 0 0 100%;
            @include rpmin(sx){
                flex: 0 0 50%;
            }
            @include rpmin(md){
                flex: 0 0 33.3333%;
            }
            @include rpmin(lg){
                flex: 0 0 25%;
            }

            position: relative;

            &--link{
                position: relative;
            }

            &__thumb{
                display: block;
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 114.3%;

                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    display: block;
                }
            }

            &-content{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                color: #FFF;

                padding: 19px 15px 16px 15px;
                z-index: 1;

                min-height: 80px;
                max-height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                transition: .6s all cubic-bezier(0.075, 0.82, 0.165, 1);


                &>*{
                    width: 100%;
                }
                &::after{
                    z-index: -1;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background-color: #000;
                    opacity: 0.8;
                    mix-blend-mode: multiply;
                    height: 100%;
                    transition: .6s opacity linear;
                }
            }
            &__name{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
            }

            &__position{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
            }

            &>a{
                display: block;
                width: 100%;
                height: 100%;

                &:hover, &:focus{
                    .item-content{
                        min-height: 100%;
                        text-align: center;

                        &::after{
                            opacity: .48;
                        }
                    }
                }
            }

            &-detail{
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1111;    

                // transform: translate(-50%, -50%);
                // width: 100%;
                // height: 100%;

                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                user-select: none;

                &.visible{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: initial;
                    user-select: initial;
                }

                .close{
                    display: none;
                }

                &-infor{
                    display: none;
                }
            }

            &.showed{
                // opacity: 1;
                // visibility: visible;
                // pointer-events: initial;
                // user-select: initial;

                &>a{
                    display: block;
                    width: 100%;
                    height: 100%;
    
                    .item-content{
                        min-height: 100%;
                        text-align: center;

                        &::after{
                            opacity: .48;
                        }
                    }
                }

                .item-detail{
                    // transition: .16s all linear;
                    opacity: 1;
                    visibility: visible;
                    pointer-events: initial;
                    user-select: initial;

                    backdrop-filter: blur(10px);

                    // .close{
                    //     display: block;
                    // }

                    .item-detail-infor{
                        display: block;
                    }

                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100%;
                    z-index: 1111;    
                    max-width: 100vw;

                    color: #E4E4E4;
                    opacity: 0;
                    clip-path: polygon(100% 0, 100% 0, 99% 100%, 100% 100%);
                    transition: .8s clip-path linear,
                                .4s opacity linear;
    
                    &::after{                    
                        content: "";
                        z-index: -1;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background-color: #000;
                        opacity: 0.8;
                        height: 100%;
                    }
    
                    --ptPopup: 20vh;

                    --wConttent: 96vw;
                    @include rpmin(md){
                        --wConttent: calc(100vw - 240px);
                    }
                    // @include rpmin(lg){
                    //     --wConttent: calc(81.73vw - 220px);
                    // }
    
                    .close{
                        // position: fixed;
                        // display: block;
                        // top: calc(#{var(--ptPopup)} - 80px);
                        // right: calc((100% - #{var(--wConttent)}) / 2);
                        // width: 36px;
                        // height: 36px;
    
                        // border-radius: 100%;
                        // background: transparent;
                        // border: 1px solid currentColor;

                        // transform: translateX(-50%);
    
                        // &::after, &::before{
                        //     content: "";
                        //     position: absolute;
                        //     top: 50%;
                        //     left: 50%;
                        //     transform: translate(-50%, -50%);
    
                        //     width: 54%;
                        //     height: 0;
                        //     border-bottom: 1.4px solid currentColor;
    
                        //     pointer-events: none;
                        //     user-select: none;
                        // }
    
                        // &::before{
                        //     transform: translate(-50%, -50%) rotate(45deg);
                        // }
                        // &::after{
                        //     transform: translate(-50%, -50%) rotate(-45deg);
                        // }
    
                        // cursor: pointer;
                        // transition: .16s all linear;
    
                        // &:hover, &:focus{
                        //     background: #FFF;
                        //     color: #000;
                        // }

                        display: none;
                    }
    
                    .item-detail--overflow{
                        position: relative;
    
                        display: flex;
                        padding-top: var(--ptPopup);
                        width: 100%;
                        height: 100%;
    
                        overflow: auto;

                        padding-left: calc((100vw - #{var(--wConttent)}) / 2);
                        padding-right: calc((100vw - #{var(--wConttent)}) / 2);
                    
                        max-width: 100%;

                        flex-direction: column;
                        @include rpmin(lg){
                            flex-direction: row-reverse;
                        }
                        
                        // &::-webkit-scrollbar {
                        //     opacity: 0;
                        //     visibility: hidden;
                        // }
                    }
                    
                    .item-detail-infor{
                        flex: 1;
                        padding-right: 0px;
                        
                        @include rpmin(lg){
                            padding-right: 114px;
                        }

                        .backtoall{
                            .icon{
                                [stroke]:not([stroke="none"]){
                                    stroke: currentColor;
                                }
                                [fill]:not([fill="none"]){
                                    fill: currentColor;
                                }
                            }
                        }
                    }
    
                    .wrapper{
                        @include space(padding-bottom, 100px);
                    }
    
                    .item-detail__thumb{
                        max-width: 480px;
                        width: 100%;
                        padding-top: 60px;

                        @include rpmin(lg){
                            position: sticky;
                            top: 0;
                            flex: 0 0 36%;
                            padding-top: 0;
                        }
                    }
    
                    .item-detail__name{
                        margin-top: 20px;
                        @include rpmin(lg){
                            margin-top: 60px;
                        }
                        &>*{
                            font-weight: 700;
                            @include title(38px);

                            line-height: 136%;
                        }
                    }
    
                    .item-detail-exp{
                        margin-top: 20px;
    
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 188%;
                    }
    
                    .writeux{
                        margin-top: 30px;
                        @include rpmin(lg){
                            margin-top: 60px;
                        }
                        font-weight: 500;
    
                        color: currentColor;
                    }
    
                    .item-detail__contact{
                        display: flex;
                        flex-wrap: wrap;

                        @include spGap(20px, 30px);

                        @include rpmin(lg){
                            @include spGap(20px, 60px);
                        }

                        &>*{
                            flex: 0 0 100%;
                            @include rpmin(md){
                                flex: 0 0 50%;
                            }
                        }
    
                        .contact-item{
                            &__name{
                                font-weight: 500;
                            }
                            &__val{
                                @include bold;
    
                                p{
                                    margin-top: 10px;
                                    @include rpmin(md){
                                        margin-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.is-animated{
                .item-detail{
                    opacity: 1;
                    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
                }
            }
        }
    }

    &-group{
        display: flex;
        flex-direction: column;
        align-items: center;
        .ab-section__sub,
        .ab-section-ls{
            width: 100%;
            flex: 0 0 100%;
        }

        &:nth-of-type(1){
            .ab-section-ls{
                width: 100%;
                flex: 0 0 100%;
                @include rpmin(sx){
                    width: 50%;
                    flex: 0 0 50%;
                }
                @include rpmin(md){
                    width: 33.33333%;
                    flex: 0 0 33.33333%;
                }
                @include rpmin(lg){
                    width: 25%;
                    flex: 0 0 25%;
                }
                
                .item{
                    list-style-type: none;
                    flex: 0 0 100%;
                }
            }
        }
        &:nth-of-type(2){
            .ab-section-ls{
                @include rpmin(lg){
                    width: 75%;
                    flex: 0 0 75%;
                }
                
                .item{
                    list-style-type: none;
                    flex: 0 0 100%;
                    @include rpmin(sx){
                        flex: 0 0 50%;
                    }
                    @include rpmin(md){
                        flex: 0 0 33.3333%;
                    }
                }
            }
        }
    }

    &--jobs{
        margin-top: 24px;
        .jobs-item{
            &__name{
                background-color: #000;
                color: #FFF;
                padding: 6px 10px;
                
                @include rpmin(lg){
                    padding: 8px 11px;
                }

                &>*{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            ul{
                list-style-type: none;

                &>li{
                    &:first-child{
                        margin-top: 8px;
                        @include rpmin(lg){
                            margin-top: 12px;
                        }
                    }
                    &>a{
                        display: inline-block;

                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        text-transform: capitalize;
                        color: #000;

                        padding: 6px 0;

                        transition: .16s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
                        &:hover, &:focus{
                            transform: translateX(4px);
                        }
                    }

                    border-bottom: 1px solid var(--cl_underl);
                }
            }

            &:not(:first-child){
                margin-top: 40px;
                @include rpmin(lg){
                    margin-top: 75px;
                }
            }
        }
    }

    &__location{
        margin-bottom: 10px;

        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        color: #000;
    }

    article{
        margin-top: 18px;
    }

    .writeux{
        margin-top: 18px;
        
        @include rpmin(lg){
            margin-top: 24px;
        }
    }

    .backtoall{
        display: flex;
        align-items: center;

        cursor: pointer;
        
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;

        
        @include rpmax(lg){
            position: absolute;
            top: calc(var(--ptPopup) + 0px);
            left: auto;
        }
        
        @include rpmax(md){
            left: 2vw;
        }
        .icon{
            margin-right: 4px;
            transition: .16s transform linear;
        }

        &.dark{
            .icon{
                [fill]:not([fill="none"]){
                    fill: var(--cl_txt);
                }
                [stroke]:not([stroke="none"]){
                    stroke: var(--cl_txt);
                }
            }
        }

        &>*{
            pointer-events: none;
        }

        &:hover{
            .icon{
                transform: rotate(90deg);
            }
        }
    }

    &__backtoall{
        .backtoall{
            position: relative;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;
        }
    }
}