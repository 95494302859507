br.pc{
    @include rpmax(lg){
        display: none;
    }
}

.scroll-prompt {
	position: fixed;
	z-index: 10;
	bottom: -80px;
	left: 50%;
	margin-left: -80px;
	width: 160px;
	height: 160px;

    cursor: pointer;

	.scroll-prompt-arrow-container {
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -18px;
		animation-name: bouncePrompt;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}
	.scroll-prompt-arrow {
		animation-name: opacityPrompt;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
	}
	.scroll-prompt-arrow:last-child {
		animation-direction: reverse;
		margin-top: -6px;
	}
	.scroll-prompt-arrow > div {
		width: 36px;
		height: 36px;
		border-right: 8px solid #bebebe;
		border-bottom: 8px solid #bebebe;
		transform: rotate(45deg) translateZ(1px);
	}
}

.toast-msg{
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 11111;
    // overflow: hidden;
    
    @include rpmin(lg){
        bottom: 20px;
    }
    list-style-type: none;

    li{
        display: block;
        background-color: #333;
        &.success{
            background-color: #40f467;
        }
        &.error{
            background-color: #D81A1A;
        }
        &>p{
            color: #fff;
            @include medium;
            @include content(16px);

            
            padding: 6px 8px;
            @include rpmin(lg){
                padding: 8px 12px;
            }
        }

        transform: translateX(calc(100% + 20px));
        transition: 0.6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &.active{
            transform: translateX(0);
        }

        cursor: pointer;

        &>*{
            user-select: none;
            pointer-events: none;
        }
    }

    li + li {
        margin-top: 10px;
    }
}

#popup360{
    position: fixed;
    z-index: 1010;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    --fancybox-color: #dbdbdb;
    --fancybox-hover-color: #fff;
    --fancybox-bg: rgba(24, 24, 27, 0.98);
    --fancybox-slide-gap: 10px;
    --f-spinner-width: 50px;
    --f-spinner-height: 50px;
    --f-spinner-color-1: rgba(255, 255, 255, 0.1);
    --f-spinner-color-2: #bbb;
    --f-spinner-stroke: 3.65;

    background: var(--fancybox-bg);
    opacity: var(--fancybox-opacity, 1);
    will-change: opacity;

    display: flex;
    justify-content: center;
    align-items: center;

    
    .wrapper{
        position: relative;
        max-width: 98%;
        max-height: 98%;
        width: var(--wContainer);
        


        background-color: var(--cl_gray);

        &--close{
            position: absolute;
            top: -38px;
            right: 0;
            width: 34px;
            height: 34px;
            border-radius: 4px;
            color: var(--fancybox-color,#fff);
            opacity: .75;

            background: none;
            outline: none;
            border: none;

            --f-button-svg-width: 22px;
            --f-button-svg-height: 22px;


            &:hover{
                opacity: 1;
            }

            @media screen and (max-width: 578px){
                --f-button-svg-width: 18px;
                --f-button-svg-height: 18px;
                --f-button-svg-filter: none;
                top: 5px;
                right: 5px;
                border-radius: 50%;
                color: #fff;
                background: rgba(0,0,0,.6);
            }

            svg{
                width: var(--f-button-svg-width);
                height: var(--f-button-svg-height);
                fill: var(--f-button-svg-fill);
                stroke: currentColor;
                stroke-width: var(--f-button-svg-stroke-width);
                stroke-linecap: round;
                stroke-linejoin: round;
                transition: opacity .15s ease;
                transform: var(--f-button-transform);
                filter: var(--f-button-svg-filter);
                pointer-events: none;
            }

            &::before, &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 54%;
                height: 0;
                border-bottom: 2px solid #FFF;
            }

            &::before{
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after{
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        #popup-data{
            width: 100%;
            aspect-ratio: 16/9;
            height: 80vh;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
}

.share-socials, .tags{
    margin-top: 30px;
    
    @include rpmin(lg){
        margin-top: 60px;
    }

    h3{
        font-size: 14px;
        line-height: 21px;
        color: #000;
    }

    ul{
        margin-top: 24px;
        list-style-type: none;

        display: flex;
        flex-wrap: wrap;
        
        // @include spGap(30px);

        &>li{
            // margin-left: -30px;
            // margin-top: 30px;
        }

        &>li>a{
            display: inline-block;
            &>*{
                user-select: none;
                pointer-events: none;
            }
        }

        // li + li {
        //     margin-left: 30px;
        // }
    }
}

.tags{
    a{
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        background-color: #DEDEDE;
        white-space: nowrap;
        padding: 3px 10px;
        height: 34px;
        
        color: #444C59;

        border-radius: 3px;
    }

    ul{
        @include spGap(8px);
    }
}

.select2{
    .select2-selection{
        min-height: 33px;

        text-align: left !important;
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid #000 !important;
        background-color: transparent !important;

        .select2-search__field{
            min-height: initial !important;
        }
    }
    &-results__option{
        &--highlighted{
            background-color: var(--cl_main) !important; 
        }
    }

    &-container--default .select2-selection--multiple .select2-selection__choice__remove{
        top: 50%;
        transform: translateY(-50%);
    }
}

.play-video{
    display: block;
    position: relative;

    img{
        display: block;
    }

    &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(13, 0, 76, 0.28);
        transform: translate(-50%, -50%);
        transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .icon{
        width: auto;
        height: auto;
        z-index: 1;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        *{
            transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }

    &:hover{
        &::after{
            opacity: 1;
        }
        .icon{
            transform: translate(-50%, -50%) scale(1.2);

            [fill]:not([fill="none"]){
                fill: #8BC53F;
            }
            [stroke]:not([stroke="none"]){
                stroke: #8BC53F;
            }
        }
    }
}

.backtotop{
    position: fixed;
    bottom: 20vh;
    right: calc(2vw + 2px);

    .icon{
        display: inline-block;
        transform: translateY(-6px);
    }
    
    font-size: 13px;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    font-weight: 600;
    transform: rotate(-90deg);
    transform-origin: right center;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    visibility: hidden;
    opacity: 0;
    display: none;

    z-index: 111;

    &.showed{
        display: block;
        opacity: 1;
        visibility: visible;
    }
}

.scrollToExplore{
    @include rpmax(md){
        display: none !important;
    }
    @include rpmin(md){
        @keyframes viewExplore {
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
    
        display: inline-flex;
        align-items: center;
        justify-content: center;
    
        position: fixed;
        z-index: 1004;
        top: 50%;
        right: calc(2vw + 4px);
        transform: rotate(90deg);
        transform-origin: right center;
    
        opacity: 0;
        display: none;
    
        // animation: viewExplore .3s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
        z-index: 1002;
        
        .text{
            color: var(--cl_explore, #000);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12.4px;
            line-height: 15px;
            transform: rotate(180deg);
        }
    
        .icon{
            display: flex;
            align-items: center;
            margin-left: 6px;

            path{
                &:not([fill]):not([stroke]){
                    fill: var(--cl_explore, #000);
                }
                [fill]:not([fill="none"]){
                    fill: var(--cl_explore, #000);
                }
                [stroke]:not([stroke="none"]){
                    stroke: var(--cl_explore, #000);
                }
            }
        }
        .icon-prompt{
            display: block;
            width: 36px;
            height: 20px;
            border-radius: 40px;
            color: var(--cl_explore, #000);
            border: 2px solid currentColor;
    
            position: relative;
            &::after{
                --wPoint: 4px;
                --pStart: 6px;
                --pEnd: 24px;
    
                @keyframes loopPoint {
                    0%{
                        left: var(--pStart);
                        opacity: 1;
                    }
                    50%{
                        left: var(--pEnd);
                        opacity: 1;
                    }
                    90%,100%{
                        opacity: 0;
                    }
                }
    
                content: "";
                position: absolute;
                top: 50%;
                left: var(--pEnd);
                transform: translate(0%, -50%);
                width: var(--wPoint);
                height: var(--wPoint);
                border-radius: 100%;
                background-color: currentColor;
    
                animation: loopPoint 1.2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);
            }
        }
    
        .icon-arrow{
            margin-left: 6px;
    
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 20px;
            height: 15px;
            position: relative;
            overflow: hidden;
    
            @keyframes loopArrow {
                0%{
                    opacity: 0;
                    left: 0;
                    transform: translate(-100%, -50%) rotate(-90deg);
                }
                57%{
                    opacity: 1;
                    left: 100%;
                    transform: translate(-100%, -50%) rotate(-90deg);
                }
                100%{
                    left: 100%;
                    transform: translate(0%, -50%) rotate(-90deg);
                    opacity: 0;
                }
            }
    
            &>*{
                width: 15px;
                height: 15px;
                
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0%, -50%) rotate(-90deg);
    
                animation: loopArrow 1.2s infinite linear;
            
                &:first-child{
                    animation-delay: .4s;
                }
                &:nth-of-type(2){
                    animation-delay: .8s;
                }
            }
        }
    }
}

@mixin hoverUnder{
    transition: .16s all ease-in-out;

    &::after{
        --cl_main: #{map-get($color, main)};
        // content: "";
        position: absolute;
        bottom: 0%;
        left: 0;
        width: 0%;
        height: 0;
        border-bottom: 1px solid var(--cl_main, #000);
        transition: .16s width linear;
    }

    &:hover, &:focus{
        color: var(--cl_main);

        &::after{
            width: 100%;
        }
    }
}
.hoverUnder{
    transition: .16s all ease-in-out;

    &::after{
        --cl_main: #{map-get($color, main)};
        // content: "";
        position: absolute;
        bottom: 0%;
        left: 0;
        width: 0%;
        height: 0;
        border-bottom: 1px solid var(--cl_main, #000);
        transition: .16s width linear;
    }

    &:hover, &:focus{
        color: var(--cl_main);

        &::after{
            width: 100%;
        }


    }
}

.loadmore{
    margin-top: 40px;
    text-align: center;

    a{
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        /* identical to box height, or 86% */

        text-transform: capitalize;

        color: var(--cl_b);
        cursor: pointer;

        position: relative;

        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-top: 1px solid currentColor;
        }
    }
}

.related{
    width: 100%;
    display: flex;
    flex-direction: column;

    @include space(padding-bottom, 100px);

    h2{
        font-weight: 700;
        text-align: center;
        line-height: 136%;
        // @include title(52px);
        @include title(38px);

        color: rgba(0,0,0,.75);
    }

    &-more{
        @include rpmax(md){
            order: 3;
        }
        margin-top: 20px;

        font-weight: 500;
        font-size: 14px;
        line-height: 19px;

        &>*{
            display: none;
        }

        &__view{
            display: block;
        }

        @include rpmin(md){
            display: flex;
            width: 70%;
            margin: 40px auto 0 auto;
            justify-content: space-between;
            
            &>*{
                display: block;
                background-color: #FFF;
            }

            p{
                padding-right: 24px;
            }
    
            &__view{
                padding-left: 16px;
            }
            
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% - 10px);
                height: 0;
                border-top: 1px solid #000;
                z-index: -1;
            }
        }

    }

    &-wrapper{
        margin-top: 40px;
        @include rpmin(md){
            margin-top: 50px;
        }

        display: flex;
        flex-wrap: wrap;
        
        @include spGap2(30px, 12px, 40px);

        @include rpmin(md){
            @include spGap2(52px, 12px, 50px);
        }

        .related-box{
            flex: 0 0 100%;
            
            @include rpmin(md){
                flex: 0 0 50%;
            }
            @include rpmin(lg){
                flex: 0 0 33.3333%;
            }

            display: flex;
            flex-direction: column;

            &__thumb{
                display: block;
                overflow: hidden;

                // width: 100%;
                // aspect-ratio: 105/62;
                background-color: var(--cl_gray);

                position: relative;
                width: 100%;
                display: block;
                height: 0;
                padding-top: 59.046%;
                overflow: hidden;

                &>*{
                    transition: .16s transform linear;
                }
                
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            time{
                display: block;
                margin-top: 13px;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;

                // color: #000;
                color: #8d8d8d;
                
                margin-bottom: 8px;
                @include rpmin(md){
                    margin-bottom: 10px;
                }
            }

            h3{
                font-weight: 600;
                line-height: 120%;
                // color: #000;
                color: rgba(0,0,0,.75);
                font-size: 20px;
                
                @include rpmax(md){
                    font-size: 18px;
                    text-align: left !important;
                }

                flex: 1;
            }

            &-content__desc{
                margin-top: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                // letter-spacing: 1px;
                // color: var(--cl_txt);
                color: #7c7c7c;
            }

            .related-box-content{
                flex: 1;

                display: flex;
                flex-direction: column;
            }
            &-thumb ~ .related-box-content{
                margin-top: 10px;
            }
            &-content ~ .related-box__thumb{
                margin-top: 10px;
            }

            &:hover{
                .related-box__thumb{
                    &>*{
                        transform: scale(1.08);
                    }
                }
            }
        }
    }
}

.share{
    --spaceShare: 16px;
    --topShare: 10px;

    h3{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        color: #000;
    }

    ul{
        // margin-top: 10px;
        list-style-type: none;

        display: flex;
        // @include spGap(16px, 10px);

        margin-top: calc(#{var(--spaceShare, 0px)} * (-1) + #{var(--topShare, 0px)});
        margin-left: calc(#{var(--spaceShare, 0px)} * (-1));

        &>li{
            padding-left: var(--spaceShare, 0px);
            padding-top: var(--spaceShare, 0px);
        }

        &>li>a{
            display: block;
            width: 15px;
            height: 16px;

            transform-origin: center;
            transition: .16s width cubic-bezier(0.175, 0.885, 0.32, 1.275);
            
            svg{
                display: block;
                width: 100%;
                height: 100%;
                transform-box: fill-box;
                transition: .16s all linear;
                
                [fill], [stroke]{
                    transition: .16s all linear;
                }

                [fill]:not([fill="none"]){
                    fill: currentColor;
                }
                [stroke]:not([stroke="none"]){
                    stroke: currentColor;
                }
            }
            
            @include rpmin(md){
                opacity: .8;
            }

            &:hover{
                opacity: 1;
                svg{
                    transform: scale(1.1);
                    [fill]:not([fill="none"]){
                        fill: var(--cl_main);
                    }
                    [stroke]:not([stroke="none"]){
                        stroke: var(--cl_main);
                    }
                }

            }
        }
    }
}