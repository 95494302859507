.swiper {
    width: 100%;
    height: 100%;
    
    &-slide {
        font-size: inherit;
        background: inherit;
        
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &-scrollbar{
        &.swiper-scrollbar-horizontal{
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6px;
            border-radius: 0;
            background-color: rgba(255, 255, 255, 0.8);
        }

        &-drag{
            border-radius: inherit;
            background-color: var(--cl_main, #000);
        }
    }

    &-pagination{
        display: inline-block;
        &-bullet{
            border-radius: 0;
            --swiper-pagination-bullet-size: 4px;
            --swiper-pagination-color: #FFF;
            --swiper-pagination-bullet-inactive-color: #FFF;
            --swiper-pagination-bullet-inactive-opacity: 0.58;
        }
        @include rpmin(md){
            display: none;
        }
    }

    &-button{
        &-next, &-prev{
            display: none;
            @include rpmin(md){
                display: flex;

                --swiper-navigation-size: 36px;

                width: var(--swiper-navigation-size);
                height: var(--swiper-navigation-size);
                border: 2.5px solid #FFF;
                border-radius: 100%;
    
                &::after{
                    content: none;
                }
            }
        }
        &-next{
            right: var(--sPos, 2vw);
        }
        &-prev{
            left: var(--sPos, 2vw);
        }
    }
}
