._404{
    position: relative;
    overflow: hidden;
    &-bg{

        .icon{
            &-left, &-right{
                z-index: -1;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }
            &-left{
                transform:translate(-84%,-51%) scale(1.5);
                user-select: none;
                pointer-events: none;
    
            }
            &-right{
                user-select: none;
                pointer-events: none;
    
                transform: translate(1%,-49%);
            }
        }
    
    }
    &>.container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100vh - #{var(--hHeader, 0px)});
    }

    &-thumbnail{
        max-width: 100%;
        &>*{
            max-width: 100%;
        }
    }

    &__title{
        max-width: 100%;

        &>*{
            max-width: 100%;
            color: #272727;

            span{
                &.text{
                    display: none;
                }
            }
        }
    }

    &__desc{
        margin-top: 27px;
        @include rpmin(md){
            width: 68%;
        }
        @include rpmin(lg){
            width: 56%;
        }
        font-weight: 700;
        @include title(38px);
        color: #000;
        line-height: 1.2;
        @include bold;
        text-align: center;
        text-transform: uppercase;
    }

    &__link{
        @include space(margin-top, 40px);

        a{
            display: inline-flex;
            align-items: center;
            justify-content: center;

            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            text-transform: uppercase;

            padding: 6px 18px;
            background: var(--cl-b, #000);
            color: var(--cl-w, #FFF);

            transition: .3s all ease;
            .icon{
                margin-left: 12px;
                transition: .2s all cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &:hover{
                background: var(--cl_main, #000);
                .icon{
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

#errorPage{
    min-height:calc(100vh - 68px);
    
    @include rpmin(md){
        min-height:calc(100vh - 92px);
        
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}