#loading{
    position: fixed;
    background-color: #FFF;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    z-index: 99999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading{

    .icon{
        &-left, &-right, &-logo{
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &-left{
            transform: translate(-75.5%,-40%);
            user-select: none;
            pointer-events: none;

        }
        &-right{
            user-select: none;
            pointer-events: none;

            transform: translate(0%,-35%);
        }

        &-logo{
            display: flex;
            &-x{
                height: 113px;
                transform: scale(12) translate(11%,5.2%);
                svg{
                    width: auto;
                    height: 100%;

                    [fill]:not([fill="none"]){
                        fill: #00000000;

                        &[fill="#ED1C24"]{
                            fill: #ed1c2300;
                        }
                        &[fill="#231F20"]{
                            fill: #231f2000;
                        }
                    }
                }
            }
            &-img{
                opacity: 0;
                height: 97px;
                transform: translate(-30px, 16px);

                &>*{
                    display: inline-block;
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    --step1: 1s;
    &.is-loaded{
        .icon{
            &-logo{
                // transform: translate(-50%,-50%) scale(.2212);
                transform: translate(-50%,-50%) scale(.4412);
                transition: var(--step1) transform linear;

                &-x{
                    transform: scale(1) translate(0%,0%);
                    transition: var(--step1) transform linear;

                    svg{
                        [fill]:not([fill="none"]){
                            transition: var(--step1) transform linear;

                            &[fill="#ED1C24"]{
                                fill: #ed1c23;
                            }
                            &[fill="#231F20"]{
                                fill: #231f20;
                            }
                        }
                    }
                }
                &-img{
                    opacity: 1;
                    transition: var(--step1) transform linear;
                }
            }
            &-left{
                transform: translate(-78.3%,-42.5%) scale(1.50124);
                transition: var(--step1) transform linear;
            }
            &-right{
                transform: translate(11%,-36.3%) scale(1.06143);
                transition: var(--step1) transform linear;
            }
        }
    }
    --step2: .8s;
    &.is-loaded-step2{
        .icon{
            &-logo{
                transform: translate(-50%,-50%) scale(0.82);
                transition: var(--step2) transform linear;

                @media screen and (min-width:420px){
                    transform: translate(-50%,-50%) scale(1);
                }
                &-x{
                    transform: scale(1) translate(0%,0%);
                    transition: var(--step2) transform linear;

                    svg{
                        [fill]:not([fill="none"]){
                            transition: var(--step2) transform linear;
                            &[fill="#ED1C24"]{
                                fill: #ed1c23;
                            }
                            &[fill="#231F20"]{
                                fill: #231f20;
                            }
                        }
                    }
                }
                &-img{
                    opacity: 1;
                    transition: var(--step2) transform linear;
                }

                &::after{
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 150%;
                    height: calc(100% + 10px);
                    background-color: #FFF;
                    opacity: 0;
                    transition: .3s opacity ease;
                }
            }
            &-left{
                transform: translate(-100%,-42.5%) scale(1.50124);
                opacity: 0;
                transition: var(--step2) transform linear;

            }
            &-right{
                transform: translate(100%,-36.3%) scale(1.06143);
                opacity: 0;
                transition: var(--step2) transform linear;

            }
        }
    }

    --stepRemove: 1s;
    &.is-removed{
        .icon{
            &-logo{
                transform: translate(-50%,-50%) scale(0.82);
                transition: var(--stepRemove) all cubic-bezier(0.6, -0.28, 0.735, 0.045);
                @media screen and (min-width:420px){
                    transform: translate(-50%,-50%) scale(1);
                }

                &-x{
                    transform: scale(.9) translate(0%, 114px);
                    transition: var(--stepRemove) all cubic-bezier(0.6, -0.28, 0.735, 0.045);
                    svg{
                        [fill]:not([fill="none"]){
                            &[fill="#ED1C24"]{
                                fill: #ed1c23;
                            }
                            &[fill="#231F20"]{
                                fill: #231f20;
                            }
                        }
                    }
                }
                &-img{
                    transform: translate(-30px, calc(114px + 16px));
                    opacity: 1;
                    transition: var(--stepRemove) all cubic-bezier(0.6, -0.28, 0.735, 0.045);
                }

                &::after{
                    opacity: 1;
                }
            }
            &-left{
                transform: translate(-100%,-42.5%) scale(1.50124);
                opacity: 0;
            }
            &-right{
                transform: translate(100%,-36.3%) scale(1.06143);
                opacity: 0;
            }
        }
    }
}